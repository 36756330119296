body {
  background-color: #1e1e1e;
  min-width: 1024px;
  font-family: "Roboto", "Noto Sans CJK JP", sans-serif;
  font-size: 12px;
}

/*login form*/
#dialog-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  z-index: 1;
}

.login-bg {
  background: url("../images/login_dialog_bg.svg") no-repeat top center;
  background-size: cover;
}

#dialog-wrapper .card-header {
  margin-bottom: 0;
  border-bottom: none;
  text-align: left;
  background: transparent;
}

#login_title {
  width: 273px;
  height: 78px;
  margin-top: 13px;
}

#login-form .card-body {
  width: 285px;
  padding: 17px 0 25px 0;
}

#login-form .password-setting.card-body {
  width: 285px;
  padding: 0;
}

.login-message {
  color: #ff1b37;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #2f7eeb;
  width: 100%;
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input:focus {
  outline: none;
}

.login-icon {
  width: 30px;
  height: 30px;
  margin-right: 11px;
}

.login-id-wrapper {
  height: 37px;
  margin-bottom: 13px;
}

.login-pass-wrapper {
  height: 37px;
  margin-bottom: 35px;
}

.password-setting .login-pass-wrapper {
  height: 37px;
  margin-bottom: 15px;
}

.btn-close {
  width: 131px;
  height: 31px;
  color: #e0e0e0;
  background: #3c3c3c;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
  margin-right: 20px;
}

.btn-login {
  width: 131px;
  height: 31px;
  color: #fbfbfb;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding: 0px;
  background: #2f7eeb !important;
}
