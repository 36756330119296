/* dark */
body {
  --detail-bg-color: #1e1e1e; /** 詳細画面のデフォルト背景色 */
  --detail-txt-color: #e0e0e0; /** 詳細画面のデフォルト文字色 */

  --detail-line-color: #3c3c3c; /** テーブルや行のライン色 */
  --detail-key-name-txt-color: #8f8f8f; /** 表のタイトル */

  --positive-btn-bg-color: #2f7eeb; /** 複数選択肢UI - positive ボタン背景色 */
  --positive-btn-bd-color: #2f7eeb; /** 複数選択肢UI - positive ボタンボーダー色 */
  --positive-btn-txt-color: #e0e0e0; /** 複数選択肢UI - positive ボタン文字色 */

  --negative-btn-bg-color: #3c3c3c; /** 複数選択肢UI - negative ボタン背景色 */
  --negative-btn-bd-color: #3c3c3c; /** 複数選択肢UI - negative ボタンボーダー色 */
  --negative-btn-txt-color: #e0e0e0; /** 複数選択肢UI - negative ボタン文字色 */

  --action-btn-bg-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */
  --action-btn-bd-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */
  --action-btn-txt-color: #e0e0e0; /** 単一選択肢UI ボタン背景色 */

  --warning-btn-bg-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-bd-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-txt-color: #ffffff; /** 単一選択肢UI 警告 ボタン背景色 */

  --warning-txt-color: var(--warning-btn-bg-color); /** 警告メッセージ色 */

  --detail-nav-active-bg-color: #09456d; /** タブバー 選択時 背景色 */
  --detail-nav-active-txt-color: #fafbfc; /** タブバー 選択時 文字色 */
  --detail-nav-bg-color: #3c3c3c; /** タブバー 通常時 背景色 */
  --detail-nav-txt-color: #8f8f8f; /** タブバー 選択時 文字色 */

  --text-color-caption: #ffffff;

  --blame-bg-color: #303030;
  --blame-txt-color: #e0e0e0;
  --blame-user-txt-color: #498ba7;

  --empty-background-image: url("../images/themes/dark/ic_symbol.svg");

  --trash-box-active-image: url("../images/themes/dark/ic_trash_box_active.svg");

  --ic-link: url("../images/themes/dark/ic_link.svg");

  --ic-arrow-up: url("../images/themes/dark/ic_arrow_up.svg");
  --ic-arrow-down: url("../images/themes/dark/ic_arrow_down.svg");
  --ic-arrow-up-hover: url("../images/themes/dark/ic_arrow_up_hover.svg");
  --ic-arrow-down-hover: url("../images/themes/dark/ic_arrow_down_hover.svg");

  --ic-close: url("../images/themes/dark/ic-db-close.svg");
  --db-drop-line-color: #ffffff;

  --ic-drag: url("../images/themes/dark/ic_drag_handle.svg");
}

/* light */
body.light {
  --detail-bg-color: #ffffff; /** 詳細画面のデフォルト背景色 */
  --detail-txt-color: #23282c; /** 詳細画面のデフォルト文字色 */
  --detail-line-color: #e1e4e8; /** テーブルや行のライン色 */
  --detail-key-name-txt-color: #8f8f8f; /** 表のタイトル */

  --positive-btn-bd-color: #2f7eeb; /** 複数選択肢UI - positive ボタンボーダー色 */
  --positive-btn-bg-color: #2f7eeb; /** 複数選択肢UI - positive ボタン背景色 */
  --positive-btn-txt-color: #ffffff; /** 複数選択肢UI - positive ボタン文字色 */

  --negative-btn-bd-color: #e1e4e8; /** 複数選択肢UI - negative ボタン背景色 */
  --negative-btn-bg-color: #fafbfc; /** 複数選択肢UI - negative ボタンボーダー色 */
  --negative-btn-txt-color: #8f8f8f; /** 複数選択肢UI - negative ボタン文字色 */

  --action-btn-bg-color: #fafbfc; /** 単一選択肢UI ボタン背景色 */
  --action-btn-bd-color: #e1e4e8; /** 単一選択肢UI ボタン背景色 */
  --action-btn-txt-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */

  --warning-btn-bg-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-bd-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-txt-color: #ffffff; /** 単一選択肢UI 警告 ボタン背景色 */

  --warning-txt-color: var(--warning-btn-bg-color); /** 警告メッセージ色 */

  --detail-nav-active-bg-color: #fafbfc; /** タブバー 選択時 背景色 */
  --detail-nav-active-txt-color: #23282c; /** タブバー 選択時 文字色 */
  --detail-nav-bg-color: #ececec; /** タブバー 通常時 背景色 */
  --detail-nav-txt-color: #23282c; /** タブバー 選択時 文字色 */

  --text-color-caption: #000000;

  --blame-bg-color: #eeeeee;
  --blame-txt-color: #3c3c3c;
  --blame-user-txt-color: #498ba7;

  --empty-background-image: url("../images/themes/light/ic_symbol.svg");

  --trash-box-active-image: url("../images/themes/light/ic_trash_box_active.svg");

  --ic-link: url("../images/themes/light/ic_link.svg");

  --ic-arrow-up: url("../images/themes/light/ic_arrow_up.svg");
  --ic-arrow-down: url("../images/themes/light/ic_arrow_down.svg");
  --ic-arrow-up-hover: url("../images/themes/light/ic_arrow_up_hover.svg");
  --ic-arrow-down-hover: url("../images/themes/light/ic_arrow_down_hover.svg");

  --ic-close: url("../images/themes/light/ic-db-close.svg");
  --db-drop-line-color: #000000;

  --ic-drag: url("../images/themes/light/ic_drag_handle.svg");
}

body {
  background-color: var(--detail-bg-color);
}

.nav .nav-item .nav-link {
  color: var(--detail-nav-txt-color);
}

.header {
  background: #333333;
}

/********** Detail View ************/
.detail-content {
  background-color: var(--detail-bg-color);
}

#headMenu .nav .nav-item .nav-link {
  background: var(--detail-nav-bg-color);
  color: var(--detail-nav-txt-color);
  opacity: 1;
}

#headMenu .nav .nav-item .nav-link.active,
#headMenu .nav .nav-item .nav-link:focus,
#headMenu .nav .nav-item .nav-link:hover {
  background: var(--detail-nav-active-bg-color);
  color: var(--text-color-caption);
}

#headMenu .nav .nav-item .nav-link.active {
  background: var(--detail-nav-active-bg-color);
  color: var(--text-color-caption);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.btn {
  color: var(--text-color-caption);
}

.btn-positive {
  background: var(--positive-btn-bg-color);
  border-color: var(--positive-btn-bd-color);
  color: var(--positive-btn-txt-color);
}

.btn-positive:hover {
  color: var(--positive-btn-bg-color);
  border-color: var(--positive-btn-bd-color);
  background-color: var(--positive-btn-txt-color);
}

.btn-cancel {
  background: var(--negative-btn-bg-color);
  border-color: var(--negative-btn-bd-color);
  color: var(--negative-btn-txt-color);
}

.btn-cancel:hover {
  color: var(--negative-btn-bg-color);
  border-color: var(--negative-btn-bd-color);
  background-color: var(--negative-btn-txt-color);
}

.btn-warning {
  background: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-txt-color);
}

.custom-border-bottom {
  border-bottom: 1px solid var(--detail-line-color);
}

.key {
  color: var(--detail-key-name-txt-color);
}

.value {
  color: var(--detail-txt-color);
}

.detail-main-title {
  color: var(--detail-txt-color);
}

.detail-small-title {
  color: var(--detail-txt-color);
}

.detail-small-title-edit {
  color: var(--detail-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.detail-sub-title {
  color: var(--detail-txt-color);
}

.updated-by {
  background: var(--blame-bg-color) !important;
  color: var(--blame-txt-color) !important;
}

.blame-user-name {
  color: var(--blame-user-txt-color) !important;
}

.table td,
.table th {
  border-bottom: 1px solid var(--detail-line-color) !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.table td {
  color: var(--detail-key-name-txt-color);
}

.table th {
  color: var(--detail-txt-color);
}

.table td.editable {
  border-bottom: 3px solid var(--positive-btn-bg-color) !important;
}

.custom-input {
  color: var(--detail-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}
td .custom-input {
  color: var(--detail-txt-color);
  border-bottom: none;
}

.btn-action,
.btn-detach {
  background-color: var(--action-btn-bg-color);
  border-color: var(--action-btn-bd-color);
  color: var(--action-btn-txt-color);
}

.btn-action:hover,
.btn-detach:hover {
  color: var(--action-btn-bg-color);
  border-color: var(--action-btn-bd-color);
  background-color: var(--action-btn-txt-color);
}

.btn-detach.disabled {
  text-decoration: none;
  opacity: 0.4;
}

.btn-warning {
  background-color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bd-color);
  color: var(--warning-btn-txt-color);
}

.btn-warning:hover {
  color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bd-color);
  background-color: var(--warning-btn-txt-color);
}

#confirmModal .modal-bg {
  background: url("../images/login_dialog_bg.svg") no-repeat top left;
  background-size: cover;
}

.ic-edittable {
  background: right center no-repeat url("../images/ic_edittable.svg");
  padding-right: 20px;
}

.light .ic-edittable {
  background: right center no-repeat url("../images/ic_editable_light.svg");
  padding-right: 20px;
}

.detail-small-title.ic-edittable {
  background-position-y: bottom;
}

.detail-setting .value {
  color: var(--text-color-caption);
}

.theme-btn-gray {
  background: var(--detail-nav-bg-color);
  color: var(--negative-btn-txt-color);
}

.theme-btn-gray.active {
  background: var(--detail-nav-active-bg-color);
  color: var(--detail-nav-active-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.custom-btn-blue {
  color: #fbfbfb;
  background: var(--positive-btn-bg-color);
}

.adm-detail-main-title {
  color: var(--detail-txt-color);
}

.detail-setting-label {
  color: #8f8f8f;
}

#mode {
  color: #8f8f8f;
}

#scrollTop img {
  cursor: pointer;
}

#scrollBottom img {
  cursor: pointer;
}

.inputText {
  border: 1px solid var(--lv-bd-color-01);
  background: var(--lv-bg-02);
  color: var(--detail-txt-color);
  text-indent: 5px;
}
.inputText:focus {
  border: 1px solid var(--lv-btn-bd-color-01);
}

.lv-filter::placeholder {
  color: var(--lv-btn-txt-color-02);
}
.dialog-message {
  color: var(--warning-txt-color);
}

.admin-edit-acction-title {
  color: var(--detail-txt-color);
}

.active-button {
  fill: #ffffff !important;
}
.inactive-button {
  fill: #ffffff !important;
}

#detail .row {
  line-height: 33px;
}

.custom-dropdown select {
  color: ;
  /*hide original SELECT element:*/
}

.select-selected {
  background: #fafafa;
  color: var(--action-btn-txt-color);
}

.blame {
  background: var(--blame-bg-color) !important;
  color: var(--blame-txt-color) !important;
}

.help tr {
  border-bottom: solid 1px var(--detail-line-color);
}

.help {
  color: var(--detail-txt-color);
}

.help th {
  background-color: var(--action-btn-bg-color);
}

.empty {
  background: var(--empty-background-image) no-repeat center center;
}

.detail-group-sub-title {
  color: var(--lv-txt-color);
}

.modal-text {
  color: var(--lv-txt-color-02);
}

.custom-border-bottom.is-affiliation {
  color: var(--lv-txt-color);
}

.lv-no-group {
  color: var(--lv-txt-color-03);
}

.lv-no-policy {
  color: var(--lv-txt-color-03);
}
