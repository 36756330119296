.list-view {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.list-view-data {
  position: inherit;
  height: calc(100% - 116px);
  width: 300px;
  overflow: auto;
}

.policy-link,
.group-link
.account-link {
  text-decoration: none;
}

a.policy-link:link,
a.group-link:link,
a.account-link:link {
  text-decoration: none;
}

a.policy-link:hover,
a.group-link:hover,
a.account-link:hover {
  cursor: pointer;
}

#lv-summary {
  height: 20px;
}

.tree {
  width: 100%;
  font-size: 12px;
}

.lv-progress {
  height: 2px !important;
  margin-left: 1px;
  background-color: var(--lv-bg-01);
}

.lv-update-date {
  font-size: 10px;
  line-height: 13px;
  text-align: right;
}

.lv-table-number {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.lv-hist-item-date {
  font-size: 9px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
}

.lv-hist-item-tit-1 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.lv-hist-item-tit-2 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.lv-hist-item-tit-3 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

#lv-filter-wrapper {
  margin: 4px;
}

.lv-filter {
  width: 292px !important;
  height: 33px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0.375rem
}

.lv-filter::placeholder {
  font-size: 12px;
  vertical-align: middle;
}

.lv-ic-arrow {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 15px;
}

.lv-ic-arrow a {
  background: none;
}

.lv-group-arrow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: left;
  padding-left: 15px;
}

.lv-group-arrow a {
  flex: 1;
  background: none;
}

.lv-group-item,
.lv-policy-item  {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: left;
}

.lv-group-item a,
.lv-policy-item a {
  flex: 1;
  background: none;
}

.lv-ic-instance {
  background: left center no-repeat url("../images/ic_instance.svg");
  padding-left: 25px;
}

.lv-ic-db-oracle {
  background: left center no-repeat url("../images/ic_db_oracle.svg");
  padding-left: 25px;
}

.lv-ic-db-sqlserver {
  background: left center no-repeat url("../images/ic_db_sqlserver.svg");
  padding-left: 25px;
}

.lv-ic-schema {
  background: left center no-repeat url("../images/ic_schema.svg");
  padding-left: 25px;
}

.lv-ic-table {
  background: 0% 50% no-repeat url("../images/ic_table.svg");
  padding-left: 25px;
}

.lv-group-instance {
  text-align: right;
  color: var(--lv-txt-color-03);
}

.lv-instance-name {
  margin-left: 20px;
}

.lv-instance {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 33px;
}

.lv-database {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 20px;
  line-height: 33px;
}

.lv-database a.lv-db-link,
.lv-table a.lv-db-link {
  color: inherit;
  text-decoration: none;
}

.lv-schema {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 40px;
  line-height: 33px;
}

.lv-table {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 60px;
  line-height: 33px;
}

.lv-nested {
  display: none;
}

.lv-instance:hover,
.lv-database:hover,
.lv-schema:hover,
.lv-table:hover,
.lv-row-item:hover,
.lv-company,
.lv-category,
.lv-code,
.lv-instance.active,
.lv-database.active,
.lv-schema.active,
.lv-table.active,
.lv-row-item.active,
.lv-company.active,
.lv-category.active,
.lv-code.active,
.lv-instance:focus,
.lv-database:focus,
.lv-schema:focus,
.lv-table:focus,
.lv-row-item:focus,
.lv-company:focus,
.lv-category:focus,
.lv-code:focus,
#search-result .list-group-item:hover,
#search-result .list-group-item.active,
#search-result .list-group-item:focus,
#lv-admin .list-group-item:hover,
#lv-admin .list-group-item.active,
#lv-admin .list-group-item:focus {
  cursor: pointer;
}

.lv-scroll-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.lv-scroll-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

#search-result .lv-instance {
  line-height: 33px;
}

#search-result .list-group-item {
  background: transparent;
  border: none;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  font-size: 12px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#lv-admin .list-group-item {
  background: transparent;
  border: none;
  padding: 0 0.5rem 0 5px;
  font-size: 12px;
  line-height: 33px;
}

.lv-admin-face-label {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.lv-acc-name {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.lv-acc-date {
  font-size: 10px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
}

.lv-acc-line-h {
  line-height: 33px !important;
}

#lv-edit-hist .lv-row-item {
  height: 55px !important;
}

.lv-admin-name {
  font-size: 12px;
}

.lv-admin-date {
  font-size: 10px;
  text-align: right;
}

.lv-company {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 33px;
  color: var(--lv-txt-color-header1);
}

.lv-category {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 20px;
  line-height: 33px;
}

.lv-code {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 40px;
  line-height: 33px;
}
.code-selected {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.lv-db-group {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 1.5rem;
  background: var(--lv-bg-group);
  color: var(--lv-txt-color-03);
  width: 292px;
}

@import url("listview-theme.css");
