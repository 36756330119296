/* dark */
body {
  /** background color */
  --lv-bg-color: #222525;
  --lv-bg-02: #3c3c3c;
  --lv-bg-03: #2f4858;
  --lv-bd-color-01: #979797;
  --lv-bg-group: #444444;
  /** text color */
  --lv-txt-color-01: #f8f9fa;
  --lv-txt-color-02: #979797;
  --lv-txt-color-03: #e0e0e0;
  --lv-txt-color-05: #f5a623;
  --lv-txt-color-06: #498ba7;
  --lv-txt-color-07: #979797;
  --lv-txt-color: #8f8f8f;
  --lv-txt-color-header1: #e0e0e0;
  --lv-txt-color-within-3days: #e0e0e0;
  --lv-txt-color-within-1week: #e0e0e0;
  --lv-txt-color-over-1week: #e0e0e0;
  /** input */
  --lv-txt-placefolder: #979797;
  --lv-txt-input-color: #f8f9fa;
  /** icon */
  --lv-ic-arrow-opened: left center no-repeat
    url("../images/themes/dark/arrow_opened.svg");
  --lv-ic-arrow-closed: left center no-repeat
    url("../images/themes/dark/arrow_closed.svg");
  --lv-ic-scroll-top: url("../images/themes/dark/ic_scroll_to_top.svg")
    no-repeat;
  --lv-ic-scroll-bottom: url("../images/themes/dark/ic_scroll_to_bottom.svg")
    no-repeat;
  --lv-ic-setting-general: url("../images/themes/dark/ic_settings_applications.svg")
    no-repeat;
  --lv-ic-setting-admin: url("../images/themes/dark/ic_admin.svg") no-repeat;
  --lv-ic-databases: url("../images/themes/dark/ic_databases.svg") no-repeat;
  --lv-ic-edit-history: url("../images/themes/dark/ic_edit_history.svg")
    no-repeat;
  --lv-ic-search: url("../images/themes/dark/ic_search.svg") no-repeat;
  --lv-ic-add: url("../images/themes/dark/ic_add.svg") no-repeat;
  /** scroll **/
  --lv-scroll-color: #888;
  --lv-scroll-color-corner: #1e1e1e;
}

/* light */
body.light {
  /** background color */
  --lv-bg-color: #fafbfc;
  --lv-bg-02: #ffffff;
  --lv-bg-03: #e6e8ea;
  --lv-bd-color-01: #979797;
  --lv-bg-group: #dddddd;
  /** text color */
  --lv-txt-color-01: #e6e8ea;
  --lv-txt-color-02: #979797;
  --lv-txt-color-03: #000000;
  --lv-txt-color-05: #ff009c;
  --lv-txt-color-06: #498ba7;
  --lv-txt-color-07: #586069;
  --lv-txt-color: #586069;
  --lv-txt-color-header1: #586069;
  --lv-txt-color-within-3days: #586069;
  --lv-txt-color-within-1week: #586069;
  --lv-txt-color-over-1week: #586069;

  /** input */
  --lv-txt-placefolder: #979797;
  --lv-txt-input-color: #979797;
  /** icon */
  --lv-ic-arrow-opened: left center no-repeat
    url("../images/themes/light/arrow_opened.svg");
  --lv-ic-arrow-closed: left center no-repeat
    url("../images/themes/light/arrow_closed.svg");
  --lv-ic-scroll-top: url("../images/themes/light/ic_scroll_to_top.svg")
    no-repeat;
  --lv-ic-scroll-bottom: url("../images/themes/light/ic_scroll_to_bottom.svg")
    no-repeat;
  --lv-ic-setting-general: url("../images/themes/light/ic_settings_applications.svg")
    no-repeat;
  --lv-ic-setting-admin: url("../images/themes/light/ic_admin.svg") no-repeat;
  --lv-ic-databases: url("../images/themes/light/ic_databases.svg") no-repeat;
  --lv-ic-edit-history: url("../images/themes/light/ic_edit_history.svg")
    no-repeat;
  --lv-ic-search: url("../images/themes/light/ic_search.svg") no-repeat;
  --lv-ic-add: url("../images/themes/light/ic_add.svg") no-repeat;
  /** scroll **/
  --lv-scroll-color: #888;
  --lv-scroll-color-corner: #ffffff;
}

.list-view,
.list-view a {
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color);
}

.list-view-modal,
.list-view-modal a {
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color);
}

.list-view-data {
  color: var(--lv-txt-color);
}

.database-link {
  color: inherit;
  text-decoration: none;
  background-color: var(--lv-bg-color);
  width: 292px;
}

a.database-link:hover {
  color: var(--lv-txt-color-03);
}

.lv-progress-color {
  background-color: #ff009c;
}

.lv-update-date {
  color: var(--lv-txt-color-05);
}

.lv-table-number {
  color: #8f8f8f;
}

.lv-filter,
.lv-filter:focus {
  background: var(--lv-bg-02) !important;
  border: 1px solid var(--lv-bd-color-01);
  color: var(--lv-txt-input-color);
}

.lv-filter::placeholder {
  color: var(--lv-txt-placefolder);
}

/* icon arrow */
.lv-ic-arrow {
  background: var(--lv-ic-arrow-closed);
}

.lv-ic-arrow.opened {
  background: var(--lv-ic-arrow-opened);
}

.lv-group-arrow {
  background: var(--lv-ic-arrow-closed);
}

.lv-group-arrow.opened {
  background: var(--lv-ic-arrow-opened);
}

.lv-ic-add {
  background: var(--lv-ic-add);
}

.lv-instance:hover,
.lv-database:hover,
.lv-schema:hover,
.lv-table:hover,
.lv-row-item:hover,
.lv-company:hover,
.lv-category:hover,
.lv-code:hover,
.lv-instance.active,
.lv-database.active,
.lv-schema.active,
.lv-table.active,
.lv-row-item.active,
.lv_company:active,
.lv-category:active,
.lv-code:active,
.lv-instance:focus,
.lv-database:focus,
.lv-schema:focus,
.lv-table:focus,
.lv-row-item:focus,
.lv-company:active,
.lv-category:active,
.lv-code:active,
#search-result .list-group-item:hover,
#search-result .list-group-item.active,
#search-result .list-group-item:focus,
#lv-admin .list-group-item:hover,
#lv-admin .list-group-item.active,
#lv-admin .list-group-item:focus,
#lv-setting .lv-row-item:hover,
#lv-setting .lv-row-item.active,
#lv-setting .lv-row-item:focus {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.lv-topic .lv-topic-item:hover,
.lv-topic.active .lv-topic-item {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}
.lv-topic-item {
  padding: 5px;
}

.topic-item-title {
  color: var(--lv-txt-color-03);
  width: 100%;
  margin-bottom: 5px;
}

.topic-item-user {
  width: 30%;
  display: inline-block;
}

.topic-item-date {
  display: inline-block;
  width: 70%;
  text-align: end;
}

.lv-instance {
  color: var(--lv-txt-color-header1);
}

.lv-scroll-top {
  background: var(--lv-ic-scroll-top);
}

.lv-scroll-bottom {
  background: var(--lv-ic-scroll-bottom);
}

.highlight {
  color: #f5a623;
}

.text-color-1 {
  color: #fff3e0;
}

.text-color-2 {
  color: #ffb74d;
}

.text-color-3 {
  color: #e65100;
}
/* history list view */
.lv-hist-item-tit-1 {
  color: var(--lv-txt-color-06);
}
/* 
.lv-hist-item-tit-2 {
  color: var(--lv-txt-color);
} */

.lv-hist-item-tit-3 {
  color: var(--lv-txt-color-03);
}

.lv-hist-item-date {
  color: #8f8f8f;
}

.lv-group-item-date {
  color: #8f8f8f;
}

/* search result */
/* #search-result .list-group-item {
  color: var(--lv-txt-color);
} */

/* setting list view  */
/* #lv-setting .lv-row-item {
  color: var(--lv-txt-color);
} */

#lv-setting .lv-setting-general {
  background: var(--lv-ic-setting-general);
}

#lv-setting .lv-setting-admin {
  background: var(--lv-ic-setting-admin);
}

.lv-acc-name {
  color: var(--lv-txt-color-header1);
}

.within-3-days {
  color: var(--lv-txt-color-within-3days);
}

.within-1-week {
  color: var(--lv-txt-color-within-1week);
}

.over-1-week {
  color: var(--lv-txt-color-over-1week);
}

.lv-admin-name {
  color: var(--lv-txt-color-04);
}

.lv-acc-name {
  color: var(--lv-txt-color-03);
}

/* help */
#lv-help .lv-help-databases {
  background: var(--lv-ic-databases);
}
#lv-help .lv-help-edit-history {
  background: var(--lv-ic-edit-history);
}
#lv-help .lv-help-search {
  background: var(--lv-ic-search);
}
