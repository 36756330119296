body {
  background-color: #1e1e1e;
  min-width: 1024px;
  font-family: "Roboto", "Noto Sans CJK JP", sans-serif;
  font-size: 12px;
}

/*login form*/
#dialog-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  z-index: 1;
}

.login-bg {
  background: url(/static/media/login_dialog_bg.7274d29d.svg) no-repeat top center;
  background-size: cover;
}

#dialog-wrapper .card-header {
  margin-bottom: 0;
  border-bottom: none;
  text-align: left;
  background: transparent;
}

#login_title {
  width: 273px;
  height: 78px;
  margin-top: 13px;
}

#login-form .card-body {
  width: 285px;
  padding: 17px 0 25px 0;
}

#login-form .password-setting.card-body {
  width: 285px;
  padding: 0;
}

.login-message {
  color: #ff1b37;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #2f7eeb;
  width: 100%;
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input:focus {
  outline: none;
}

.login-icon {
  width: 30px;
  height: 30px;
  margin-right: 11px;
}

.login-id-wrapper {
  height: 37px;
  margin-bottom: 13px;
}

.login-pass-wrapper {
  height: 37px;
  margin-bottom: 35px;
}

.password-setting .login-pass-wrapper {
  height: 37px;
  margin-bottom: 15px;
}

.btn-close {
  width: 131px;
  height: 31px;
  color: #e0e0e0;
  background: #3c3c3c;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
  margin-right: 20px;
}

.btn-login {
  width: 131px;
  height: 31px;
  color: #fbfbfb;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding: 0px;
  background: #2f7eeb !important;
}

/* dark */
body {
  --detail-bg-color: #1e1e1e; /** 詳細画面のデフォルト背景色 */
  --detail-txt-color: #e0e0e0; /** 詳細画面のデフォルト文字色 */

  --detail-line-color: #3c3c3c; /** テーブルや行のライン色 */
  --detail-key-name-txt-color: #8f8f8f; /** 表のタイトル */

  --positive-btn-bg-color: #2f7eeb; /** 複数選択肢UI - positive ボタン背景色 */
  --positive-btn-bd-color: #2f7eeb; /** 複数選択肢UI - positive ボタンボーダー色 */
  --positive-btn-txt-color: #e0e0e0; /** 複数選択肢UI - positive ボタン文字色 */

  --negative-btn-bg-color: #3c3c3c; /** 複数選択肢UI - negative ボタン背景色 */
  --negative-btn-bd-color: #3c3c3c; /** 複数選択肢UI - negative ボタンボーダー色 */
  --negative-btn-txt-color: #e0e0e0; /** 複数選択肢UI - negative ボタン文字色 */

  --action-btn-bg-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */
  --action-btn-bd-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */
  --action-btn-txt-color: #e0e0e0; /** 単一選択肢UI ボタン背景色 */

  --warning-btn-bg-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-bd-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-txt-color: #ffffff; /** 単一選択肢UI 警告 ボタン背景色 */

  --warning-txt-color: var(--warning-btn-bg-color); /** 警告メッセージ色 */

  --detail-nav-active-bg-color: #09456d; /** タブバー 選択時 背景色 */
  --detail-nav-active-txt-color: #fafbfc; /** タブバー 選択時 文字色 */
  --detail-nav-bg-color: #3c3c3c; /** タブバー 通常時 背景色 */
  --detail-nav-txt-color: #8f8f8f; /** タブバー 選択時 文字色 */

  --text-color-caption: #ffffff;

  --blame-bg-color: #303030;
  --blame-txt-color: #e0e0e0;
  --blame-user-txt-color: #498ba7;

  --empty-background-image: url(/static/media/ic_symbol.fb4be3b3.svg);

  --trash-box-active-image: url(/static/media/ic_trash_box_active.46a82b90.svg);

  --ic-link: url(/static/media/ic_link.021fc7cf.svg);

  --ic-arrow-up: url(/static/media/ic_arrow_up.739fe6f1.svg);
  --ic-arrow-down: url(/static/media/ic_arrow_down.ac61e1ba.svg);
  --ic-arrow-up-hover: url(/static/media/ic_arrow_up_hover.1cc31549.svg);
  --ic-arrow-down-hover: url(/static/media/ic_arrow_down_hover.81e36a84.svg);

  --ic-close: url(/static/media/ic-db-close.20ea91c1.svg);
  --db-drop-line-color: #ffffff;

  --ic-drag: url(/static/media/ic_drag_handle.43b59aed.svg);
}

/* light */
body.light {
  --detail-bg-color: #ffffff; /** 詳細画面のデフォルト背景色 */
  --detail-txt-color: #23282c; /** 詳細画面のデフォルト文字色 */
  --detail-line-color: #e1e4e8; /** テーブルや行のライン色 */
  --detail-key-name-txt-color: #8f8f8f; /** 表のタイトル */

  --positive-btn-bd-color: #2f7eeb; /** 複数選択肢UI - positive ボタンボーダー色 */
  --positive-btn-bg-color: #2f7eeb; /** 複数選択肢UI - positive ボタン背景色 */
  --positive-btn-txt-color: #ffffff; /** 複数選択肢UI - positive ボタン文字色 */

  --negative-btn-bd-color: #e1e4e8; /** 複数選択肢UI - negative ボタン背景色 */
  --negative-btn-bg-color: #fafbfc; /** 複数選択肢UI - negative ボタンボーダー色 */
  --negative-btn-txt-color: #8f8f8f; /** 複数選択肢UI - negative ボタン文字色 */

  --action-btn-bg-color: #fafbfc; /** 単一選択肢UI ボタン背景色 */
  --action-btn-bd-color: #e1e4e8; /** 単一選択肢UI ボタン背景色 */
  --action-btn-txt-color: #3c3c3c; /** 単一選択肢UI ボタン背景色 */

  --warning-btn-bg-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-bd-color: #e53935; /** 単一選択肢UI 警告 ボタン背景色 */
  --warning-btn-txt-color: #ffffff; /** 単一選択肢UI 警告 ボタン背景色 */

  --warning-txt-color: var(--warning-btn-bg-color); /** 警告メッセージ色 */

  --detail-nav-active-bg-color: #fafbfc; /** タブバー 選択時 背景色 */
  --detail-nav-active-txt-color: #23282c; /** タブバー 選択時 文字色 */
  --detail-nav-bg-color: #ececec; /** タブバー 通常時 背景色 */
  --detail-nav-txt-color: #23282c; /** タブバー 選択時 文字色 */

  --text-color-caption: #000000;

  --blame-bg-color: #eeeeee;
  --blame-txt-color: #3c3c3c;
  --blame-user-txt-color: #498ba7;

  --empty-background-image: url(/static/media/ic_symbol.bc81f716.svg);

  --trash-box-active-image: url(/static/media/ic_trash_box_active.662550c1.svg);

  --ic-link: url(/static/media/ic_link.a44c242e.svg);

  --ic-arrow-up: url(/static/media/ic_arrow_up.8cba016d.svg);
  --ic-arrow-down: url(/static/media/ic_arrow_down.8acfde0f.svg);
  --ic-arrow-up-hover: url(/static/media/ic_arrow_up_hover.739fe6f1.svg);
  --ic-arrow-down-hover: url(/static/media/ic_arrow_down_hover.ac61e1ba.svg);

  --ic-close: url(/static/media/ic-db-close.1ced8420.svg);
  --db-drop-line-color: #000000;

  --ic-drag: url(/static/media/ic_drag_handle.5de44420.svg);
}

body {
  background-color: var(--detail-bg-color);
}

.nav .nav-item .nav-link {
  color: var(--detail-nav-txt-color);
}

.header {
  background: #333333;
}

/********** Detail View ************/
.detail-content {
  background-color: var(--detail-bg-color);
}

#headMenu .nav .nav-item .nav-link {
  background: var(--detail-nav-bg-color);
  color: var(--detail-nav-txt-color);
  opacity: 1;
}

#headMenu .nav .nav-item .nav-link.active,
#headMenu .nav .nav-item .nav-link:focus,
#headMenu .nav .nav-item .nav-link:hover {
  background: var(--detail-nav-active-bg-color);
  color: var(--text-color-caption);
}

#headMenu .nav .nav-item .nav-link.active {
  background: var(--detail-nav-active-bg-color);
  color: var(--text-color-caption);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.btn {
  color: var(--text-color-caption);
}

.btn-positive {
  background: var(--positive-btn-bg-color);
  border-color: var(--positive-btn-bd-color);
  color: var(--positive-btn-txt-color);
}

.btn-positive:hover {
  color: var(--positive-btn-bg-color);
  border-color: var(--positive-btn-bd-color);
  background-color: var(--positive-btn-txt-color);
}

.btn-cancel {
  background: var(--negative-btn-bg-color);
  border-color: var(--negative-btn-bd-color);
  color: var(--negative-btn-txt-color);
}

.btn-cancel:hover {
  color: var(--negative-btn-bg-color);
  border-color: var(--negative-btn-bd-color);
  background-color: var(--negative-btn-txt-color);
}

.btn-warning {
  background: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-txt-color);
}

.custom-border-bottom {
  border-bottom: 1px solid var(--detail-line-color);
}

.key {
  color: var(--detail-key-name-txt-color);
}

.value {
  color: var(--detail-txt-color);
}

.detail-main-title {
  color: var(--detail-txt-color);
}

.detail-small-title {
  color: var(--detail-txt-color);
}

.detail-small-title-edit {
  color: var(--detail-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.detail-sub-title {
  color: var(--detail-txt-color);
}

.updated-by {
  background: var(--blame-bg-color) !important;
  color: var(--blame-txt-color) !important;
}

.blame-user-name {
  color: var(--blame-user-txt-color) !important;
}

.table td,
.table th {
  border-bottom: 1px solid var(--detail-line-color) !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.table td {
  color: var(--detail-key-name-txt-color);
}

.table th {
  color: var(--detail-txt-color);
}

.table td.editable {
  border-bottom: 3px solid var(--positive-btn-bg-color) !important;
}

.custom-input {
  color: var(--detail-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}
td .custom-input {
  color: var(--detail-txt-color);
  border-bottom: none;
}

.btn-action,
.btn-detach {
  background-color: var(--action-btn-bg-color);
  border-color: var(--action-btn-bd-color);
  color: var(--action-btn-txt-color);
}

.btn-action:hover,
.btn-detach:hover {
  color: var(--action-btn-bg-color);
  border-color: var(--action-btn-bd-color);
  background-color: var(--action-btn-txt-color);
}

.btn-detach.disabled {
  text-decoration: none;
  opacity: 0.4;
}

.btn-warning {
  background-color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bd-color);
  color: var(--warning-btn-txt-color);
}

.btn-warning:hover {
  color: var(--warning-btn-bg-color);
  border-color: var(--warning-btn-bd-color);
  background-color: var(--warning-btn-txt-color);
}

#confirmModal .modal-bg {
  background: url(/static/media/login_dialog_bg.7274d29d.svg) no-repeat top left;
  background-size: cover;
}

.ic-edittable {
  background: right center no-repeat url(/static/media/ic_edittable.6a3f0841.svg);
  padding-right: 20px;
}

.light .ic-edittable {
  background: right center no-repeat url(/static/media/ic_editable_light.01584d18.svg);
  padding-right: 20px;
}

.detail-small-title.ic-edittable {
  background-position-y: bottom;
}

.detail-setting .value {
  color: var(--text-color-caption);
}

.theme-btn-gray {
  background: var(--detail-nav-bg-color);
  color: var(--negative-btn-txt-color);
}

.theme-btn-gray.active {
  background: var(--detail-nav-active-bg-color);
  color: var(--detail-nav-active-txt-color);
  border-bottom: 3px solid var(--positive-btn-bg-color);
}

.custom-btn-blue {
  color: #fbfbfb;
  background: var(--positive-btn-bg-color);
}

.adm-detail-main-title {
  color: var(--detail-txt-color);
}

.detail-setting-label {
  color: #8f8f8f;
}

#mode {
  color: #8f8f8f;
}

#scrollTop img {
  cursor: pointer;
}

#scrollBottom img {
  cursor: pointer;
}

.inputText {
  border: 1px solid var(--lv-bd-color-01);
  background: var(--lv-bg-02);
  color: var(--detail-txt-color);
  text-indent: 5px;
}
.inputText:focus {
  border: 1px solid var(--lv-btn-bd-color-01);
}

.lv-filter::-webkit-input-placeholder {
  color: var(--lv-btn-txt-color-02);
}

.lv-filter::placeholder {
  color: var(--lv-btn-txt-color-02);
}
.dialog-message {
  color: var(--warning-txt-color);
}

.admin-edit-acction-title {
  color: var(--detail-txt-color);
}

.active-button {
  fill: #ffffff !important;
}
.inactive-button {
  fill: #ffffff !important;
}

#detail .row {
  line-height: 33px;
}

.custom-dropdown select {
  color: ;
  /*hide original SELECT element:*/
}

.select-selected {
  background: #fafafa;
  color: var(--action-btn-txt-color);
}

.blame {
  background: var(--blame-bg-color) !important;
  color: var(--blame-txt-color) !important;
}

.help tr {
  border-bottom: solid 1px var(--detail-line-color);
}

.help {
  color: var(--detail-txt-color);
}

.help th {
  background-color: var(--action-btn-bg-color);
}

.empty {
  background: var(--empty-background-image) no-repeat center center;
}

.detail-group-sub-title {
  color: var(--lv-txt-color);
}

.modal-text {
  color: var(--lv-txt-color-02);
}

.custom-border-bottom.is-affiliation {
  color: var(--lv-txt-color);
}

.lv-no-group {
  color: var(--lv-txt-color-03);
}

.lv-no-policy {
  color: var(--lv-txt-color-03);
}

body {
  min-width: 1024px;
  font-family: "Roboto", "Noto Sans CJK JP", sans-serif;
  font-size: 12px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.pointer {
  cursor: pointer;
}

.icon-size-25 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.icon-size-24 {
  width: 24px;
  height: 24px;
}

.icon-size-20 {
  width: 20px;
  height: 20px;
}

.icon-size-15 {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.activity-bar {
  height: calc(100% - 44px);
  width: 44px;
  position: fixed;
  z-index: 1;
  top: initial;
  left: initial;
  background-color: #333333;
  overflow-x: hidden;
  padding-top: 0; /* IE 10+ */
  -webkit-user-select: none;
  user-select: none;
}

.nav .nav-item .nav-link {
  opacity: 0.44;
  display: block;
  text-align: center;
}

.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:hover {
  opacity: 1;
}

.content {
  margin-left: 44px;
}
.container-fluid {
  width: initial;
  padding-right: initial;
  padding-left: initial;
  margin-left: initial;
  margin-right: initial;
  margin-left: initial;
}

.wrapper {
  margin-left: initial;
  margin-top: 44px;
}

.header {
  height: 44px;
  position: fixed;
  width: 100%;
  top: 0;
}
.header .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}
.header .col .logo-img {
  margin-left: 9px;
  width: 147px;
}

.header-toggle-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

.header-toggle-wrapper #lv-toggle-support {
  width: 300px;
}

.list-view {
  height: calc(100% - 44px);
  width: 300px;
  position: fixed;
}

.list-view-modal {
  height: 100%;
  width: 300px;
}

.list-view-modal a {
  text-decoration: none;
}
.list-view a {
  text-decoration: none;
}

/********** Detail View ************/
.detail-content {
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 44px);
  width: calc(100% - 344px);
  color: var(--detail-txt-color);
}

.detail-header {
  height: 33px !important;
  width: 60px !important;
}

#detail .row {
  line-height: 33px;
}

#detail,
#add {
  padding-left: 24px;
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 104px);
  overflow: hidden;
  width: calc(100% - 344px);
}

#detail-modal {
  padding-left: 24px;
}

#detail.detail-setting {
  padding-top: 24px;
  height: calc(100% - 44px);
}

.detail-content.lv-closed,
#detail.lv-closed {
  margin-left: 0px !important;
  width: calc(100% - 44px);
}

#detail-margin .row {
  line-height: 33px;
}

#detail-margin {
  padding-left: 24px;
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 104px);
  overflow: hidden;
  width: calc(100% - 394px);
}

#detail-margin:hover {
  overflow: overlay;
}

#detail-margin.lv-closed {
  margin-left: 0px !important;
  width: calc(100% - 344px);
}

.fixed-layout {
  width: 692px !important;
}

#headMenu {
  position: fixed;
  min-width: 636px;
  height: 60px;
}

#headMenu .nav .nav-item {
  font-size: 12px;
  white-space: nowrap;
}

#headMenu .nav .nav-item .nav-link.active,
#headMenu .nav .nav-item .nav-link:focus,
#headMenu .nav .nav-item .nav-link:hover {
  height: 33px;
}

.key {
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  white-space: nowrap;
}

.value {
  font-size: 12px;
  line-height: 33px;
  text-align: left;
  white-space: nowrap;
}

.detail-main-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

.detail-small-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.detail-small-title-edit {
  font-size: 16px;
  font-weight: 400;
  /* line-height: 48px; */
  vertical-align: bottom;
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  min-height: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
}

.detail-sub-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
}

.detail-line-height {
  line-height: 26px;
}

.updated-by {
  font-size: 10px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  padding: 0 4px;
  margin-top: 4px;
  border-radius: 3px;
}

.diff-pad-left {
  padding-right: 12px !important;
}

/* info-diff */
#diffLeft {
  position: fixed;
  padding-left: 24px;
  height: calc(100% - 191px);
  width: calc((100% - 368px) / 2);
  overflow: hidden;
}

#diffLeft.lv-closed {
  width: calc((100% - 44px) / 2);
}

#diffRight {
  position: fixed;
  padding-left: 24px;
  margin-left: calc((100% - 344px) / 2);
  height: calc(100% - 191px);
  width: calc(((100% - 370px) / 2) - 40px);
  overflow: hidden;
}

#diffRight.lv-closed {
  margin-left: calc((100% - 44px) / 2);
  width: calc((100% - 44px) / 2);
}

.nowrap {
  white-space: nowrap;
}

.table td,
.table th {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.custom-input {
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  margin-bottom: -3px;
  min-height: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  line-height: 1.2em;
}

.custom-input:focus {
  outline: none;
}

.custom-select {
  padding: 2px 5px;
  height: 100%;
}

[contenteditable]:focus {
  outline: none;
}

.fade {
  transition-duration: 0.01s;
}

/*login form*/
#dialog-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100%;
  height: 100%;
  z-index: 1;
}

.login-bg {
  background: url(/static/media/login_dialog_bg.7274d29d.svg) no-repeat top center;
  background-size: cover;
}

#dialog-wrapper .card-header {
  margin-bottom: 0;
  border-bottom: none;
  text-align: left;
  background: transparent;
}

#login_title {
  width: 273px;
  height: 78px;
  margin-top: 13px;
}

#login-form .card-body {
  width: 285px;
  padding: 17px 0 25px 0;
}

.err-message-wrapper {
  height: 26px;
  white-space: pre;
}

.help-block {
  font-size: 12px;
  color: red;
}

.login-input {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input:focus {
  outline: none;
}

.login-icon {
  width: 30px;
  height: 30px;
  margin-right: 11px;
}

.login-id-wrapper {
  height: 37px;
  margin-bottom: 13px;
}

.login-pass-wrapper {
  height: 37px;
  margin-bottom: 35px;
}

/** button common param */
.btn {
  font-size: 12px;
  height: 33px;
  min-width: 88px;
  font-weight: bold;
}

.btn-close {
  width: 131px;
  height: 31px;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
}

.btn-login {
  width: 131px;
  height: 31px;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding: 0px;
}

/*.login form*/

.acc-detail {
  display: flex !important;
  flex-direction: column !important;
  width: 700px;
  min-width: 600px;
}

.flex-fill {
  flex: 1 1;
}

.acc-detail .card {
  background: transparent;
}

.modal .btn {
  margin: 5px;
}

.custom-btn-ng {
  font-size: 13px;
  width: 131px;
  height: 31px;
  font-weight: 700;
}

.custom-btn-ok {
  font-size: 13px;
  width: 131px;
  height: 31px;
  font-weight: 700;
}

.custom-btn-pw-change {
  width: 158px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#confirmModal .modal-bg {
  background: url(/static/media/login_dialog_bg.7274d29d.svg) no-repeat top left;
  background-size: cover;
}

#confirmModal .modal-dialog {
  width: 406px;
}

#confirmModal .modal-content {
  max-height: 200px;
  height: 360px;
}

.wrap-word {
  white-space: normal !important;
  word-wrap: break-word !important;
}
/*
.custom-btn-gray {
  width: 120px !important;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
} */

.detail-setting .value {
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.detail-setting .sub-title {
  width: 17%;
}

.detail-setting .sub-detail {
  width: 80%;
}

.theme-btn-gray {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: 60px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.theme-btn-gray.active {
  width: 60px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.custom-btn-blue {
  width: 120px !important;
  height: 33px;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
}

.adm-detail-main-title {
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.detail-setting-label {
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#mode {
  width: 120px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.btn-clear-cache {
  width: 120px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
}

.inputText {
  width: 300px;
  height: 33px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#login-form {
  width: 406px;
  height: 332px;
}

.dialog-message {
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: pre;
}

.date-period-dropdown {
  width: 74px !important;
}

.company-dropdown {
  width: 154px !important;
}

.admin-edit-acction-title {
  height: 20px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 21px;
}

.active-button {
  opacity: 1 !important;
}
.inactive-button {
  opacity: 0.44 !important;
}

.user-menu-modal {
  position: absolute;
  top: 35px;
  right: 5px;
  bottom: 40px;
  border: none;
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: var(--lv-bg-color);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  color: var(--action-btn-txt-color);
  width: 140px;
  height: 115px;
}
.user-menu-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: none;
  z-index: 2;
}
.user-menu-username {
  color: var(--detail-key-name-txt-color);
}
.modal-close {
  cursor: pointer;
  float: right;
}

.header-toggle-wrapper #toggle-button-open {
  position: fixed;
  left: 344px;
}
.header-toggle-wrapper #toggle-button-close {
  position: fixed;
  left: 44px;
}
.pre-format {
  white-space: pre-line;
  line-height: 1.2em;
}

#diffLeft:hover,
#diffRight:hover,
#detail:hover {
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-corner {
  background: var(--lv-scroll-color-corner);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lv-scroll-color);
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--lv-scroll-color);
}

.row {
  margin-right: initial;
  margin-left: initial;
}

.help h1 {
  font-size: 200%;
  font-weight: bolder;
  margin-bottom: 20px;
}
.help h2 {
  font-size: 140%;
  margin-left: 10px;
  padding-left: 10px;
  border-left: inset 5px #5d96ca;
  margin-bottom: 20px;
}
.help h3 {
  font-size: 120%;
}
.help h4 {
  font-size: 100%;
}
.help p {
  padding-right: 30px;
}

.help li {
  margin-bottom: 20px;
}

.help table {
  margin-left: 30px;
  min-width: 80%;
  margin-bottom: 20px;
}

.help th {
  padding-left: 10px;
}

.help tr {
  height: 2.3em;
}

.help td {
  padding-left: 10px;
}

.empty {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.btn-mdl-code_mng {
  position: absolute;
  left: 504px;
}
.btn-mdl {
  cursor: pointer;
  display: inline-block;
}
.mdl-ttl {
  margin: 0 auto 15px;
  color: var(--detail-txt-color);
}
.mdl-btn-close {
  font-size: 25px;
  height: 25px;
  line-height: 25px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 6px;
  width: 25px;
  color: var(--detail-txt-color);
}
.mdl-btn-add {
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  height: 25px;
  line-height: 25px;
  margin-right: 16px;
  margin-top: 16px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 3px;
  width: 25px;
}
.mdl-btn-add:focus {
  outline: none;
}
.mdl-grid {
  display: grid;
  width: 100%;
}
.mdl-grid.grid-detail-apply {
  grid-template-columns: 300px 1fr;
  color: var(--detail-txt-color);
}

.mdl-grid.grid-detail {
  grid-template-columns: 64px 1fr;
  color: var(--detail-txt-color);
  padding-right: 0.5rem;
}
.mdl-grid.grid-detail span {
  margin-bottom: 6px;
  padding: 6px 0;
}
.mdl-grid.grid-detail .mdl-txtbox,
.mdl-formarea {
  margin-top: 0px;
}
.mdl-grid.grid-detail .mdl-txtbox,
.mdl-formarea .mdl-number {
  border: none;
  outline: none;
}
.mdl-formarea span {
  padding-bottom: 3px;
}
.mdl-formarea .mdl-number {
  text-align: right;
}
.mdl-grid.mdl-grid-result {
  grid-template-columns: 1fr 250px;
}
.mdl-grid.mdl-list-inner {
  grid-template-columns: minmax(150px, 1fr) 150px 100px 150px 100px;
}
.mdl-grid.mdl-list-detail {
  grid-template-columns: 24px 1fr;
}
.mdl-grid.mdl-list-detail span:first-child::after {
  content: ":";
}

.modal-dialog.modal-lg.mdl-lg {
  max-width: 70%;
}
.mdl-grid.mdl-w50 {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
}
.mdl-list-outer-head {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mdl-list-outer {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}
.mdl-list-outer.history-diff {
  height: 155px;
}
.mdl-list-outer-value {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 175px;
  overflow-y: auto;
}
.mdl-list-outer.border {
  margin-left: 12px;
  padding: 6px;
}
.mdl-list-inner {
  border-bottom: 1px solid #8f8f8f;
}
.mdl-list-inner span {
  display: block;
  margin: 6px 0;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mdl-list-result li:not(.list-head):hover {
  background-color: #2f7eeb;
  color: #ffffff;
}
.mdl-border {
  border-bottom: 1px solid #8f8f8f !important;
}
.mdl-border-active {
  border-bottom: 3px solid #2f7eeb !important;
}
.mdl-edit li:not(.list-head) > span:not(.mdl-btn-del) {
  border-bottom: 1px solid #8f8f8f;
  margin-right: 12px;
}
.mdl-edit li.list-head > span:not(:first-child),
.mdl-edit li:not(.list-head) span.mdl-btn-del {
  text-align: center;
}
.mdl-grid.mdl-list-edit {
  grid-template-columns: 54px 1fr 30px;
}
.mdl-grid.mdl-list-edit span {
  height: auto;
  margin-bottom: 6px;
  padding: 6px 0;
  color: var(--detail-txt-color);
}
.mdl-btn-del.btn-danger {
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  height: 25px;
  line-height: 25px;
  margin-top: 3px;
  text-align: center;
  width: 25px;
}
.modal#mdl-code_mng-edit {
  background-color: rgba(33, 37, 41, 0.5);
}
.btn-position-right {
  right: 21px;
  position: absolute;
}
.btn-position-left {
  left: 21px;
  position: absolute;
}
.modal-footer .btn-position-left {
  bottom: 16px;
}
.code-definition-modal {
  border: none;
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  width: auto;
  position: relative;
}
.code-definition-overray {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.code-link {
  margin-right: 5px;
  cursor: pointer;
  background: var(--ic-link) no-repeat;
  width: 20px;
  height: 15px;
  display: inline-block;
}
.code-info {
  width: 800px;
}
.tip-content {
  color: #3386e4;
}
.react-tooltip-lite {
  border-radius: 4px;
}
.topic-comment-border {
  border-top: 1px solid var(--detail-line-color);
}

.topic-delete {
  margin: 0 0 0 auto;
}

.topic-comment {
  white-space: pre-line;
  line-height: 1.2em;
  font-size: 12px;
  text-align: left;
  color: var(--detail-txt-color);
}

.topic-comment.deleted {
  color: var(--detail-key-name-txt-color);
}

.topic-comment-editor {
  position: fixed;
  bottom: 10px;
  padding-left: 24px;
  width: 100%;
  border-top: 2px solid var(--detail-line-color);
}

.topic-comment-editor > div {
  display: inline-block;
  width: 50%;
}

.topic-comment-editor > div > span {
  color: var(--detail-txt-color);
}

.topic-comment-editor > button {
  display: inline-block;
}

.topic-status,
.topic-category {
  display: inline-block;
  vertical-align: middle;
  color: #1e1e1e;
  font-weight: 500;
}

.topic-status.list {
  width: 50px;
  font-size: 10px;
}

.topic-status.detail {
  width: 55px;
  font-size: 11px;
}

.topic-status.open {
  color: #87ceeb;
  border: 1px solid #87ceeb;
}

.topic-status.resolve {
  color: #a9a9a9;
  border: 1px solid #a9a9a9;
}

.topic-category.list {
  width: 45px;
  font-size: 10px;
}

.topic-category.detail {
  width: 50px;
  font-size: 13px;
}

.topic-category.request {
  background-color: #90ee90;
}

.topic-category.bug {
  background-color: #e87758;
}

.topic-category.question {
  background-color: #e07b9a;
}

.topic-category.other {
  background-color: #4682b4;
}

.topic-filter {
  display: grid;
  grid-template-columns: 75px 75px 75px 75px;
}

.trash-box {
  background: url(/static/media/ic_trash_box.47751862.svg) no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.trash-box:hover {
  background: var(--trash-box-active-image) no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.switch-resolve-color {
  color: #00cc00;
}

.switch-open-color {
  color: var(--detail-txt-color);
}

/* code */
.code-value-add {
  padding-top: 15px;
}
.code-edit {
  height: 34px;
}
.btn-add-right {
  background-color: #2f7eeb;
  border-radius: 50%;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  height: 20px !important;
  line-height: 18px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 20px !important;
  min-width: initial !important;
}
.gridlist {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  margin-bottom: 0;
}
.gridlist-ttl,
.gridlist-status {
  height: 30px;
  padding: 5px;
}
.gridlist-ttl {
  font-weight: bold;
}
.gridlist-status {
  border-bottom: 3px solid #2f7eeb !important;
}

/* ***** CSS更新 ************************************************** */
.mdl-btn-del.btn-danger {
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-top: 3px;
  text-align: center;
  width: 20px;
}

/* ***** CSS追加 ************************************************** */
.definition-add {
  cursor: pointer;
  display: block;
  padding: 3px 5px;
  width: 296px;
  display: flex;
}
.definition-add.active {
  background-color: var(--lv-bg-03);
}
.definition-add.actve,
.definition-add:hover {
  /*    background-color: #2f4858; */
  background-color: var(--lv-bg-03);
  color: #fff;
}
.definition-add-icon {
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}
.definition-add-txt {
  color: var(--lv-txt-color-03);
  line-height: 2;
}
.definition-list .list-group-item {
  background: transparent;
  border: none;
  font-size: 12px;
  line-height: 33px;
  padding: 0 0.5rem 0 1rem;
}
.definition-list .list-group-item:hover,
.definition-list .list-group-item.active {
  background-color: #2f4858;
  color: #e0e0e0;
  cursor: pointer;
}
.definition-list-date {
  color: #fff;
  font-size: 10px;
}

.definition-list-dsp,
.definition-list-edt {
  overflow-y: auto;
  min-height: 35px;
  max-height: 15em;
  /*    max-height: calc(100vh - 600px); */
  margin-bottom: 6px;
  width: 350px;
}
.definition-list-modal {
  overflow-y: auto;
  height: 100%;
  max-height: 100px;
  margin-bottom: 6px;
  width: 350px;
}

.definition-list-add {
  overflow-y: auto;
  height: 11em;
  margin-bottom: 6px;
  width: 350px;
}
.definition-grid-dsp,
.definition-grid-edt,
.definition-grid-modal,
.definition-grid-add {
  grid-template-columns: 54px 250px 30px;
}
.definition-list-dsp .definition-grid-dsp > span,
.definition-list-edt .definition-grid-edt > span,
.definition-list-modal .definition-grid-modal > span,
.definition-list-add .definition-grid-add > span {
  margin: 0 6px 5px 0;
  padding: 5px 0;
  color: var(--detail-txt-color);
}
.definition-list-dsp .definition-grid-dsp > span,
.definition-list-edt .definition-grid-edt > span,
.definition-list-modal .definition-grid-modal > span {
  border-bottom: 1px solid var(--detail-line-color);
}
.definition-list-add .definition-grid-add > span {
  border-bottom: none;
}
.definition-grid-add:hover {
  cursor: move;
}
.definition-list-dsp + .btn.btn-primary,
.definition-list-edt + .btn.btn-primary,
.definition-list-add + .btn.btn-primary {
  width: 300px;
}
.definition-list-edt .mdl-btn-del.btn-danger {
  line-height: 22px;
}
.definition-btn-left {
  justify-content: initial;
}
#detail.tab-content {
  margin-left: 0;
}
.detail-sub-emphasis {
  color: #8f8f8f;
}
.connection-list {
  overflow-y: auto;
  height: 100%;
  max-height: 275px;
}
.connection-list.detail {
  overflow-y: auto;
  height: 100%;
  max-height: 125px;
}
.connect-grid-dsp {
  border-bottom: 1px solid var(--detail-line-color);
  cursor: default;
  grid-template-columns:
    minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
    minmax(250px, 1fr) minmax(250px, 1fr);
  min-width: 800px;
}
.connect-grid-dsp.list-head {
  background-color: var(--detail-bg-color);
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.connect-grid-dsp:not(.list-head) {
  cursor: pointer;
}
.connect-grid-dsp:not(.list-head):hover {
  background-color: #2f4858;
  color: #fff;
}
.connect-grid-dsp {
  overflow: auto;
  padding: 4px 6px 4px 0;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
  color: #8f8f8f;
}
.connect-grid-dsp.list-head span {
  overflow: auto;
  padding: 6px 6px 6px 0;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
  color: var(--detail-txt-color);
}

.mdl-grid.definition-grid-basic {
  grid-template-columns: 60px 170px 120px;
}
#definition-add {
  margin-top: 18px;
}
#definition-add .definition-list-add {
  height: calc(100vh - 475px);
}
.style-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.style-scrollbar::-webkit-scrollbarkit-scrollbar-track {
  border-radius: 10px;
}
.style-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.code-basic-info-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 33px;
}

.code-basic-info-value {
  color: var(--detail-txt-color);
  line-height: 33px;
}

.code-key {
  width: 48px;
  word-wrap: break-word;
}

.code-definition-modal-bg {
  background-color: var(--detail-bg-color);
}

.code-definition-modal-input {
  background-color: var(--detail-bg-color);
  color: var(--detail-txt-color);
}

.code-definition-modal-text {
  color: var(--detail-txt-color);
}

.code-tree-filter {
  width: 50%;
}

.code-tree-filter > button {
  font-size: 10px;
  width: 144px;
  position: relative;
  display: inline-flex;
}

.code-tree-filter > button.category {
  margin: 0 4px;
}

.code-tree-filter > button.code-ame {
  margin-right: 4px;
}

.code-tree-filter > button[aria-expanded="false"]:after {
  content: var(--ic-arrow-down);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.code-tree-filter > button[aria-expanded="false"]:hover:after {
  content: var(--ic-arrow-down-hover);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.code-tree-filter > button[aria-expanded="true"]:after {
  content: var(--ic-arrow-up);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.ic-close {
  background: var(--ic-close) no-repeat;
}

.code-tree-filter > button[aria-expanded="true"]:hover:after {
  content: var(--ic-arrow-up-hover);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.mdl-list-outer-modal {
  overflow-y: auto;
  height: 10em;
}

.mdl-list-outer-instance {
  overflow-y: auto;
  width: 100%;
  padding-right: 200px;
  min-width: 600px;
}

.mdl-list-outer-group-user {
  overflow-y: auto;
  max-height: 500px;
  width: calc(100% - 202px);
  margin-left: 33px;
}

.mdl-list-outer-policy-res {
  overflow-y: hidden;
  max-height: 1000px;
}
.mdl-list-outer-policy-res:hover {
  overflow-y: auto;
}

.group-sub-title {
  width: 100%;
  padding-right: 200px;
}

.mdl-list-outer-modal thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-code-link {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-code-link td,
.table-code-link th {
  border-bottom: 1px solid var(--detail-line-color) !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.table-code-link td {
  color: var(--detail-key-name-txt-color);
}

.table-code-link th {
  color: var(--detail-txt-color);
  background-color: var(--detail-bg-color);
}

.modal-code-untied {
  width: 100%;
  text-align: center;
}

.db-drop-target {
  border: 2px dotted var(--db-drop-line-color);
  margin-right: 10px;
}

.db-drop-msg {
  position: absolute;
  margin-top: 200px;
}

.diff-bottom {
  height: 76px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 1px solid var(--detail-line-color);
}

.diff-bottom-left {
  display: grid;
  grid-template-columns: calc(100% - 170px) 160px;
}

.diff-bottom-right {
  display: grid;
  grid-template-columns: 50px calc(100% - 220px) 160px;
}

.table-path {
  word-wrap: break-word;
}

.select-date-right {
  position: absolute;
  right: 30px;
}

.move {
  cursor: move;
}

.position-container {
  width: 100%;
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color-02);
  border-radius: 0;
}

.position-container.select {
  width: 100%;
  background-color: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.ic-drag {
  background: var(--ic-drag) left no-repeat;
  background-position: 5px 5px !important;
}

.position-drag {
  margin-left: 30px !important;
}

.position-no-drag {
  margin-left: 10px !important;
}

.lv-group-user {
  max-height: 150px;
  min-height: 30px;
  overflow-y: scroll;
}

.mr-8px {
  margin-right: 8px;
}

.modal-bottom-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 80vh;
  overflow-y: auto;
}

.mdl-list-modal-outer {
  height: 50vh;
  overflow: auto;
}

.mdl-list-modal-user {
  grid-template-columns: 60px 200px 1fr 150px;
  border-bottom: 1px solid #8f8f8f;
}

.mdl-list-modal-group,
.mdl-list-modal-policy,
.mdl-list-modal-user-group {
  grid-template-columns: 60px 250px 1fr;
  border-bottom: 1px solid #8f8f8f;
}

.mdl-list-modal-user span,
.mdl-list-modal-group span,
.mdl-list-modal-policy span,
.mdl-list-modal-user-group span {
  display: block;
  margin: 6px 0;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mdl-list-user {
  grid-template-columns: 30% 70%;
  border-bottom: 1px solid var(--detail-line-color);
}

.mdl-list-user-edit {
  grid-template-columns: 80px 20px 80px;
}

.mdl-list-user-header {
  width: calc(100% - 170px);
}

.policy-menu {
  line-height: unset !important;
}

.mdl-list-policy-meta {
  grid-template-columns: 320px 35px 35px;
}

.mdl-list-policy-dbcode {
  grid-template-columns: 320px 35px 35px 35px 35px;
}

.mdl-list-policy-res {
  grid-template-columns: 70px 150px 1fr;
}

.policy-description {
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  min-height: 100%;
  border: none;
  line-height: 1.2em;
}

#jsoneditor {
  width: 100%;
  height: 300px;
}

.no-hover{
  pointer-events: none;
}

.break-text{
  word-break: break-all;
}

.policy-description {
  white-space: pre-line;
  word-break: break-all;
  font-size: 12px;
  text-align: left;
}

.btn-modal{
  color: #ffffff;
}
/* dark */
body {
  /** background color */
  --lv-bg-color: #222525;
  --lv-bg-02: #3c3c3c;
  --lv-bg-03: #2f4858;
  --lv-bd-color-01: #979797;
  --lv-bg-group: #444444;
  /** text color */
  --lv-txt-color-01: #f8f9fa;
  --lv-txt-color-02: #979797;
  --lv-txt-color-03: #e0e0e0;
  --lv-txt-color-05: #f5a623;
  --lv-txt-color-06: #498ba7;
  --lv-txt-color-07: #979797;
  --lv-txt-color: #8f8f8f;
  --lv-txt-color-header1: #e0e0e0;
  --lv-txt-color-within-3days: #e0e0e0;
  --lv-txt-color-within-1week: #e0e0e0;
  --lv-txt-color-over-1week: #e0e0e0;
  /** input */
  --lv-txt-placefolder: #979797;
  --lv-txt-input-color: #f8f9fa;
  /** icon */
  --lv-ic-arrow-opened: left center no-repeat
    url(/static/media/arrow_opened.aa19e3bc.svg);
  --lv-ic-arrow-closed: left center no-repeat
    url(/static/media/arrow_closed.cec899be.svg);
  --lv-ic-scroll-top: url(/static/media/ic_scroll_to_top.28c5cb31.svg)
    no-repeat;
  --lv-ic-scroll-bottom: url(/static/media/ic_scroll_to_bottom.9d8ad0c5.svg)
    no-repeat;
  --lv-ic-setting-general: url(/static/media/ic_settings_applications.17799b9b.svg)
    no-repeat;
  --lv-ic-setting-admin: url(/static/media/ic_admin.72184784.svg) no-repeat;
  --lv-ic-databases: url(/static/media/ic_databases.000cd695.svg) no-repeat;
  --lv-ic-edit-history: url(/static/media/ic_edit_history.a6f4d910.svg)
    no-repeat;
  --lv-ic-search: url(/static/media/ic_search.33e7536c.svg) no-repeat;
  --lv-ic-add: url(/static/media/ic_add.eccce63c.svg) no-repeat;
  /** scroll **/
  --lv-scroll-color: #888;
  --lv-scroll-color-corner: #1e1e1e;
}

/* light */
body.light {
  /** background color */
  --lv-bg-color: #fafbfc;
  --lv-bg-02: #ffffff;
  --lv-bg-03: #e6e8ea;
  --lv-bd-color-01: #979797;
  --lv-bg-group: #dddddd;
  /** text color */
  --lv-txt-color-01: #e6e8ea;
  --lv-txt-color-02: #979797;
  --lv-txt-color-03: #000000;
  --lv-txt-color-05: #ff009c;
  --lv-txt-color-06: #498ba7;
  --lv-txt-color-07: #586069;
  --lv-txt-color: #586069;
  --lv-txt-color-header1: #586069;
  --lv-txt-color-within-3days: #586069;
  --lv-txt-color-within-1week: #586069;
  --lv-txt-color-over-1week: #586069;

  /** input */
  --lv-txt-placefolder: #979797;
  --lv-txt-input-color: #979797;
  /** icon */
  --lv-ic-arrow-opened: left center no-repeat
    url(/static/media/arrow_opened.21b6bc8c.svg);
  --lv-ic-arrow-closed: left center no-repeat
    url(/static/media/arrow_closed.0fb221d9.svg);
  --lv-ic-scroll-top: url(/static/media/ic_scroll_to_top.67e66fc1.svg)
    no-repeat;
  --lv-ic-scroll-bottom: url(/static/media/ic_scroll_to_bottom.8c5826a5.svg)
    no-repeat;
  --lv-ic-setting-general: url(/static/media/ic_settings_applications.8a3ecb93.svg)
    no-repeat;
  --lv-ic-setting-admin: url(/static/media/ic_admin.1bf2407b.svg) no-repeat;
  --lv-ic-databases: url(/static/media/ic_databases.7f0ba233.svg) no-repeat;
  --lv-ic-edit-history: url(/static/media/ic_edit_history.261db5fc.svg)
    no-repeat;
  --lv-ic-search: url(/static/media/ic_search.0201497c.svg) no-repeat;
  --lv-ic-add: url(/static/media/ic_add.cbf9967b.svg) no-repeat;
  /** scroll **/
  --lv-scroll-color: #888;
  --lv-scroll-color-corner: #ffffff;
}

.list-view,
.list-view a {
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color);
}

.list-view-modal,
.list-view-modal a {
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color);
}

.list-view-data {
  color: var(--lv-txt-color);
}

.database-link {
  color: inherit;
  text-decoration: none;
  background-color: var(--lv-bg-color);
  width: 292px;
}

a.database-link:hover {
  color: var(--lv-txt-color-03);
}

.lv-progress-color {
  background-color: #ff009c;
}

.lv-update-date {
  color: var(--lv-txt-color-05);
}

.lv-table-number {
  color: #8f8f8f;
}

.lv-filter,
.lv-filter:focus {
  background: var(--lv-bg-02) !important;
  border: 1px solid var(--lv-bd-color-01);
  color: var(--lv-txt-input-color);
}

.lv-filter::-webkit-input-placeholder {
  color: var(--lv-txt-placefolder);
}

.lv-filter::placeholder {
  color: var(--lv-txt-placefolder);
}

/* icon arrow */
.lv-ic-arrow {
  background: var(--lv-ic-arrow-closed);
}

.lv-ic-arrow.opened {
  background: var(--lv-ic-arrow-opened);
}

.lv-group-arrow {
  background: var(--lv-ic-arrow-closed);
}

.lv-group-arrow.opened {
  background: var(--lv-ic-arrow-opened);
}

.lv-ic-add {
  background: var(--lv-ic-add);
}

.lv-instance:hover,
.lv-database:hover,
.lv-schema:hover,
.lv-table:hover,
.lv-row-item:hover,
.lv-company:hover,
.lv-category:hover,
.lv-code:hover,
.lv-instance.active,
.lv-database.active,
.lv-schema.active,
.lv-table.active,
.lv-row-item.active,
.lv_company:active,
.lv-category:active,
.lv-code:active,
.lv-instance:focus,
.lv-database:focus,
.lv-schema:focus,
.lv-table:focus,
.lv-row-item:focus,
.lv-company:active,
.lv-category:active,
.lv-code:active,
#search-result .list-group-item:hover,
#search-result .list-group-item.active,
#search-result .list-group-item:focus,
#lv-admin .list-group-item:hover,
#lv-admin .list-group-item.active,
#lv-admin .list-group-item:focus,
#lv-setting .lv-row-item:hover,
#lv-setting .lv-row-item.active,
#lv-setting .lv-row-item:focus {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.lv-topic .lv-topic-item:hover,
.lv-topic.active .lv-topic-item {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}
.lv-topic-item {
  padding: 5px;
}

.topic-item-title {
  color: var(--lv-txt-color-03);
  width: 100%;
  margin-bottom: 5px;
}

.topic-item-user {
  width: 30%;
  display: inline-block;
}

.topic-item-date {
  display: inline-block;
  width: 70%;
  text-align: end;
}

.lv-instance {
  color: var(--lv-txt-color-header1);
}

.lv-scroll-top {
  background: var(--lv-ic-scroll-top);
}

.lv-scroll-bottom {
  background: var(--lv-ic-scroll-bottom);
}

.highlight {
  color: #f5a623;
}

.text-color-1 {
  color: #fff3e0;
}

.text-color-2 {
  color: #ffb74d;
}

.text-color-3 {
  color: #e65100;
}
/* history list view */
.lv-hist-item-tit-1 {
  color: var(--lv-txt-color-06);
}
/* 
.lv-hist-item-tit-2 {
  color: var(--lv-txt-color);
} */

.lv-hist-item-tit-3 {
  color: var(--lv-txt-color-03);
}

.lv-hist-item-date {
  color: #8f8f8f;
}

.lv-group-item-date {
  color: #8f8f8f;
}

/* search result */
/* #search-result .list-group-item {
  color: var(--lv-txt-color);
} */

/* setting list view  */
/* #lv-setting .lv-row-item {
  color: var(--lv-txt-color);
} */

#lv-setting .lv-setting-general {
  background: var(--lv-ic-setting-general);
}

#lv-setting .lv-setting-admin {
  background: var(--lv-ic-setting-admin);
}

.lv-acc-name {
  color: var(--lv-txt-color-header1);
}

.within-3-days {
  color: var(--lv-txt-color-within-3days);
}

.within-1-week {
  color: var(--lv-txt-color-within-1week);
}

.over-1-week {
  color: var(--lv-txt-color-over-1week);
}

.lv-admin-name {
  color: var(--lv-txt-color-04);
}

.lv-acc-name {
  color: var(--lv-txt-color-03);
}

/* help */
#lv-help .lv-help-databases {
  background: var(--lv-ic-databases);
}
#lv-help .lv-help-edit-history {
  background: var(--lv-ic-edit-history);
}
#lv-help .lv-help-search {
  background: var(--lv-ic-search);
}

.list-view {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.list-view-data {
  position: inherit;
  height: calc(100% - 116px);
  width: 300px;
  overflow: auto;
}

.policy-link,
.group-link
.account-link {
  text-decoration: none;
}

a.policy-link:link,
a.group-link:link,
a.account-link:link {
  text-decoration: none;
}

a.policy-link:hover,
a.group-link:hover,
a.account-link:hover {
  cursor: pointer;
}

#lv-summary {
  height: 20px;
}

.tree {
  width: 100%;
  font-size: 12px;
}

.lv-progress {
  height: 2px !important;
  margin-left: 1px;
  background-color: var(--lv-bg-01);
}

.lv-update-date {
  font-size: 10px;
  line-height: 13px;
  text-align: right;
}

.lv-table-number {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.lv-hist-item-date {
  font-size: 9px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
}

.lv-hist-item-tit-1 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.lv-hist-item-tit-2 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.lv-hist-item-tit-3 {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

#lv-filter-wrapper {
  margin: 4px;
}

.lv-filter {
  width: 292px !important;
  height: 33px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0.375rem
}

.lv-filter::-webkit-input-placeholder {
  font-size: 12px;
  vertical-align: middle;
}

.lv-filter::placeholder {
  font-size: 12px;
  vertical-align: middle;
}

.lv-ic-arrow {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 15px;
}

.lv-ic-arrow a {
  background: none;
}

.lv-group-arrow {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: left;
  padding-left: 15px;
}

.lv-group-arrow a {
  flex: 1 1;
  background: none;
}

.lv-group-item,
.lv-policy-item  {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: left;
}

.lv-group-item a,
.lv-policy-item a {
  flex: 1 1;
  background: none;
}

.lv-ic-instance {
  background: left center no-repeat url(/static/media/ic_instance.8d6282d7.svg);
  padding-left: 25px;
}

.lv-ic-db-oracle {
  background: left center no-repeat url(/static/media/ic_db_oracle.0ed38772.svg);
  padding-left: 25px;
}

.lv-ic-db-sqlserver {
  background: left center no-repeat url(/static/media/ic_db_sqlserver.cb7b0017.svg);
  padding-left: 25px;
}

.lv-ic-schema {
  background: left center no-repeat url(/static/media/ic_schema.05b0d85f.svg);
  padding-left: 25px;
}

.lv-ic-table {
  background: 0% 50% no-repeat url(/static/media/ic_table.5c3767fc.svg);
  padding-left: 25px;
}

.lv-group-instance {
  text-align: right;
  color: var(--lv-txt-color-03);
}

.lv-instance-name {
  margin-left: 20px;
}

.lv-instance {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 33px;
}

.lv-database {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 20px;
  line-height: 33px;
}

.lv-database a.lv-db-link,
.lv-table a.lv-db-link {
  color: inherit;
  text-decoration: none;
}

.lv-schema {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 40px;
  line-height: 33px;
}

.lv-table {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 60px;
  line-height: 33px;
}

.lv-nested {
  display: none;
}

.lv-instance:hover,
.lv-database:hover,
.lv-schema:hover,
.lv-table:hover,
.lv-row-item:hover,
.lv-company,
.lv-category,
.lv-code,
.lv-instance.active,
.lv-database.active,
.lv-schema.active,
.lv-table.active,
.lv-row-item.active,
.lv-company.active,
.lv-category.active,
.lv-code.active,
.lv-instance:focus,
.lv-database:focus,
.lv-schema:focus,
.lv-table:focus,
.lv-row-item:focus,
.lv-company:focus,
.lv-category:focus,
.lv-code:focus,
#search-result .list-group-item:hover,
#search-result .list-group-item.active,
#search-result .list-group-item:focus,
#lv-admin .list-group-item:hover,
#lv-admin .list-group-item.active,
#lv-admin .list-group-item:focus {
  cursor: pointer;
}

.lv-scroll-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.lv-scroll-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

#search-result .lv-instance {
  line-height: 33px;
}

#search-result .list-group-item {
  background: transparent;
  border: none;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  font-size: 12px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#lv-admin .list-group-item {
  background: transparent;
  border: none;
  padding: 0 0.5rem 0 5px;
  font-size: 12px;
  line-height: 33px;
}

.lv-admin-face-label {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.lv-acc-name {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.lv-acc-date {
  font-size: 10px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
}

.lv-acc-line-h {
  line-height: 33px !important;
}

#lv-edit-hist .lv-row-item {
  height: 55px !important;
}

.lv-admin-name {
  font-size: 12px;
}

.lv-admin-date {
  font-size: 10px;
  text-align: right;
}

.lv-company {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 33px;
  color: var(--lv-txt-color-header1);
}

.lv-category {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 20px;
  line-height: 33px;
}

.lv-code {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 8px 0 40px;
  line-height: 33px;
}
.code-selected {
  background: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.lv-db-group {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0 5px;
  line-height: 1.5rem;
  background: var(--lv-bg-group);
  color: var(--lv-txt-color-03);
  width: 292px;
}

/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
 .toast-title {
    font-weight: bold;
  }
  .toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }
  .toast-message a,
  .toast-message label {
    color: #FFFFFF;
  }
  .toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
  }
  .toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    -webkit-text-shadow: 0 1px 0 #ffffff;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    line-height: 1;
  }
  .toast-close-button:hover,
  .toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40);
  }
  .rtl .toast-close-button {
    left: -0.3em;
    float: left;
    right: 0.3em;
  }
  /*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
  button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-left {
    top: 12px;
    left: 12px;
  }
  .toast-top-right {
    top: 12px;
    right: 12px;
  }
  .toast-bottom-right {
    right: 12px;
    bottom: 12px;
  }
  .toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }
  #toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    /*overrides*/
  }
  #toast-container * {
    box-sizing: border-box;
  }
  #toast-container > div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
    opacity: 0.8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
  }
  #toast-container > div.rtl {
    direction: rtl;
    padding: 15px 50px 15px 15px;
    background-position: right 15px center;
  }
  #toast-container > div:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
  }
  #toast-container > .toast-info {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
  }
  #toast-container > .toast-error {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
  }
  #toast-container > .toast-success {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
  }
  #toast-container > .toast-warning {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
  }
  #toast-container.toast-top-center > div,
  #toast-container.toast-bottom-center > div {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  #toast-container.toast-top-full-width > div,
  #toast-container.toast-bottom-full-width > div {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
  .toast {
    background-color: #030303;
  }
  .toast-success {
    background-color: #51A351;
  }
  .toast-error {
    background-color: #BD362F;
  }
  .toast-info {
    background-color: #2F96B4;
  }
  .toast-warning {
    background-color: #F89406;
  }
  .toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40);
  }
  /*Responsive Design*/
  @media all and (max-width: 240px) {
    #toast-container > div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }
    #toast-container > div.rtl {
      padding: 8px 50px 8px 8px;
    }
    #toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
    #toast-container .rtl .toast-close-button {
      left: -0.2em;
      right: 0.2em;
    }
  }
  @media all and (min-width: 241px) and (max-width: 480px) {
    #toast-container > div {
      padding: 8px 8px 8px 50px;
      width: 18em;
    }
    #toast-container > div.rtl {
      padding: 8px 50px 8px 8px;
    }
    #toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
    #toast-container .rtl .toast-close-button {
      left: -0.2em;
      right: 0.2em;
    }
  }
  @media all and (min-width: 481px) and (max-width: 768px) {
    #toast-container > div {
      padding: 15px 15px 15px 50px;
      width: 25em;
    }
    #toast-container > div.rtl {
      padding: 15px 50px 15px 15px;
    }
  }
