body {
  min-width: 1024px;
  font-family: "Roboto", "Noto Sans CJK JP", sans-serif;
  font-size: 12px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.pointer {
  cursor: pointer;
}

.icon-size-25 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.icon-size-24 {
  width: 24px;
  height: 24px;
}

.icon-size-20 {
  width: 20px;
  height: 20px;
}

.icon-size-15 {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.activity-bar {
  height: calc(100% - 44px);
  width: 44px;
  position: fixed;
  z-index: 1;
  top: initial;
  left: initial;
  background-color: #333333;
  overflow-x: hidden;
  padding-top: 0;
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.nav .nav-item .nav-link {
  opacity: 0.44;
  display: block;
  text-align: center;
}

.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:hover {
  opacity: 1;
}

.content {
  margin-left: 44px;
}
.container-fluid {
  width: initial;
  padding-right: initial;
  padding-left: initial;
  margin-left: initial;
  margin-right: initial;
  margin-left: initial;
}

.wrapper {
  margin-left: initial;
  margin-top: 44px;
}

.header {
  height: 44px;
  position: fixed;
  width: 100%;
  top: 0;
}
.header .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}
.header .col .logo-img {
  margin-left: 9px;
  width: 147px;
}

.header-toggle-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

.header-toggle-wrapper #lv-toggle-support {
  width: 300px;
}

.list-view {
  height: calc(100% - 44px);
  width: 300px;
  position: fixed;
}

.list-view-modal {
  height: 100%;
  width: 300px;
}

.list-view-modal a {
  text-decoration: none;
}
.list-view a {
  text-decoration: none;
}

/********** Detail View ************/
.detail-content {
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 44px);
  width: calc(100% - 344px);
  color: var(--detail-txt-color);
}

.detail-header {
  height: 33px !important;
  width: 60px !important;
}

#detail .row {
  line-height: 33px;
}

#detail,
#add {
  padding-left: 24px;
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 104px);
  overflow: hidden;
  width: calc(100% - 344px);
}

#detail-modal {
  padding-left: 24px;
}

#detail.detail-setting {
  padding-top: 24px;
  height: calc(100% - 44px);
}

.detail-content.lv-closed,
#detail.lv-closed {
  margin-left: 0px !important;
  width: calc(100% - 44px);
}

#detail-margin .row {
  line-height: 33px;
}

#detail-margin {
  padding-left: 24px;
  position: fixed;
  margin-left: 300px;
  height: calc(100% - 104px);
  overflow: hidden;
  width: calc(100% - 394px);
}

#detail-margin:hover {
  overflow: overlay;
}

#detail-margin.lv-closed {
  margin-left: 0px !important;
  width: calc(100% - 344px);
}

.fixed-layout {
  width: 692px !important;
}

#headMenu {
  position: fixed;
  min-width: 636px;
  height: 60px;
}

#headMenu .nav .nav-item {
  font-size: 12px;
  white-space: nowrap;
}

#headMenu .nav .nav-item .nav-link.active,
#headMenu .nav .nav-item .nav-link:focus,
#headMenu .nav .nav-item .nav-link:hover {
  height: 33px;
}

.key {
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  white-space: nowrap;
}

.value {
  font-size: 12px;
  line-height: 33px;
  text-align: left;
  white-space: nowrap;
}

.detail-main-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

.detail-small-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.detail-small-title-edit {
  font-size: 16px;
  font-weight: 400;
  /* line-height: 48px; */
  vertical-align: bottom;
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  min-height: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
}

.detail-sub-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
}

.detail-line-height {
  line-height: 26px;
}

.updated-by {
  font-size: 10px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  padding: 0 4px;
  margin-top: 4px;
  border-radius: 3px;
}

.diff-pad-left {
  padding-right: 12px !important;
}

/* info-diff */
#diffLeft {
  position: fixed;
  padding-left: 24px;
  height: calc(100% - 191px);
  width: calc((100% - 368px) / 2);
  overflow: hidden;
}

#diffLeft.lv-closed {
  width: calc((100% - 44px) / 2);
}

#diffRight {
  position: fixed;
  padding-left: 24px;
  margin-left: calc((100% - 344px) / 2);
  height: calc(100% - 191px);
  width: calc(((100% - 370px) / 2) - 40px);
  overflow: hidden;
}

#diffRight.lv-closed {
  margin-left: calc((100% - 44px) / 2);
  width: calc((100% - 44px) / 2);
}

.nowrap {
  white-space: nowrap;
}

.table td,
.table th {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.custom-input {
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  margin-bottom: -3px;
  min-height: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  line-height: 1.2em;
}

.custom-input:focus {
  outline: none;
}

.custom-select {
  padding: 2px 5px;
  height: 100%;
}

[contenteditable]:focus {
  outline: none;
}

.fade {
  transition-duration: 0.01s;
}

/*login form*/
#dialog-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100%;
  height: 100%;
  z-index: 1;
}

.login-bg {
  background: url("../images/login_dialog_bg.svg") no-repeat top center;
  background-size: cover;
}

#dialog-wrapper .card-header {
  margin-bottom: 0;
  border-bottom: none;
  text-align: left;
  background: transparent;
}

#login_title {
  width: 273px;
  height: 78px;
  margin-top: 13px;
}

#login-form .card-body {
  width: 285px;
  padding: 17px 0 25px 0;
}

.err-message-wrapper {
  height: 26px;
  white-space: pre;
}

.help-block {
  font-size: 12px;
  color: red;
}

.login-input {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.login-input:focus {
  outline: none;
}

.login-icon {
  width: 30px;
  height: 30px;
  margin-right: 11px;
}

.login-id-wrapper {
  height: 37px;
  margin-bottom: 13px;
}

.login-pass-wrapper {
  height: 37px;
  margin-bottom: 35px;
}

/** button common param */
.btn {
  font-size: 12px;
  height: 33px;
  min-width: 88px;
  font-weight: bold;
}

.btn-close {
  width: 131px;
  height: 31px;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
}

.btn-login {
  width: 131px;
  height: 31px;
  font-size: 14.2px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding: 0px;
}

/*.login form*/

.acc-detail {
  display: flex !important;
  flex-direction: column !important;
  width: 700px;
  min-width: 600px;
}

.flex-fill {
  flex: 1;
}

.acc-detail .card {
  background: transparent;
}

.modal .btn {
  margin: 5px;
}

.custom-btn-ng {
  font-size: 13px;
  width: 131px;
  height: 31px;
  font-weight: 700;
}

.custom-btn-ok {
  font-size: 13px;
  width: 131px;
  height: 31px;
  font-weight: 700;
}

.custom-btn-pw-change {
  width: 158px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#confirmModal .modal-bg {
  background: url("../images/login_dialog_bg.svg") no-repeat top left;
  background-size: cover;
}

#confirmModal .modal-dialog {
  width: 406px;
}

#confirmModal .modal-content {
  max-height: 200px;
  height: 360px;
}

.wrap-word {
  white-space: normal !important;
  word-wrap: break-word !important;
}
/*
.custom-btn-gray {
  width: 120px !important;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
} */

.detail-setting .value {
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.detail-setting .sub-title {
  width: 17%;
}

.detail-setting .sub-detail {
  width: 80%;
}

.theme-btn-gray {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: 60px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.theme-btn-gray.active {
  width: 60px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.custom-btn-blue {
  width: 120px !important;
  height: 33px;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
}

.adm-detail-main-title {
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.detail-setting-label {
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#mode {
  width: 120px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

.btn-clear-cache {
  width: 120px;
  height: 33px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 0px;
}

.inputText {
  width: 300px;
  height: 33px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
}

#login-form {
  width: 406px;
  height: 332px;
}

.dialog-message {
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: pre;
}

.date-period-dropdown {
  width: 74px !important;
}

.company-dropdown {
  width: 154px !important;
}

.admin-edit-acction-title {
  height: 20px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 21px;
}

.active-button {
  opacity: 1 !important;
}
.inactive-button {
  opacity: 0.44 !important;
}

.user-menu-modal {
  position: absolute;
  top: 35px;
  right: 5px;
  bottom: 40px;
  border: none;
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: var(--lv-bg-color);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  color: var(--action-btn-txt-color);
  width: 140px;
  height: 115px;
}
.user-menu-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: none;
  z-index: 2;
}
.user-menu-username {
  color: var(--detail-key-name-txt-color);
}
.modal-close {
  cursor: pointer;
  float: right;
}

.header-toggle-wrapper #toggle-button-open {
  position: fixed;
  left: 344px;
}
.header-toggle-wrapper #toggle-button-close {
  position: fixed;
  left: 44px;
}
.pre-format {
  white-space: pre-line;
  line-height: 1.2em;
}

#diffLeft:hover,
#diffRight:hover,
#detail:hover {
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-corner {
  background: var(--lv-scroll-color-corner);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lv-scroll-color);
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--lv-scroll-color);
}
@import url("style-theme.css");

.row {
  margin-right: initial;
  margin-left: initial;
}

.help h1 {
  font-size: 200%;
  font-weight: bolder;
  margin-bottom: 20px;
}
.help h2 {
  font-size: 140%;
  margin-left: 10px;
  padding-left: 10px;
  border-left: inset 5px #5d96ca;
  margin-bottom: 20px;
}
.help h3 {
  font-size: 120%;
}
.help h4 {
  font-size: 100%;
}
.help p {
  padding-right: 30px;
}

.help li {
  margin-bottom: 20px;
}

.help table {
  margin-left: 30px;
  min-width: 80%;
  margin-bottom: 20px;
}

.help th {
  padding-left: 10px;
}

.help tr {
  height: 2.3em;
}

.help td {
  padding-left: 10px;
}

.empty {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.btn-mdl-code_mng {
  position: absolute;
  left: 504px;
}
.btn-mdl {
  cursor: pointer;
  display: inline-block;
}
.mdl-ttl {
  margin: 0 auto 15px;
  color: var(--detail-txt-color);
}
.mdl-btn-close {
  font-size: 25px;
  height: 25px;
  line-height: 25px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 6px;
  width: 25px;
  color: var(--detail-txt-color);
}
.mdl-btn-add {
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  height: 25px;
  line-height: 25px;
  margin-right: 16px;
  margin-top: 16px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 3px;
  width: 25px;
}
.mdl-btn-add:focus {
  outline: none;
}
.mdl-grid {
  display: grid;
  width: 100%;
}
.mdl-grid.grid-detail-apply {
  grid-template-columns: 300px 1fr;
  color: var(--detail-txt-color);
}

.mdl-grid.grid-detail {
  grid-template-columns: 64px 1fr;
  color: var(--detail-txt-color);
  padding-right: 0.5rem;
}
.mdl-grid.grid-detail span {
  margin-bottom: 6px;
  padding: 6px 0;
}
.mdl-grid.grid-detail .mdl-txtbox,
.mdl-formarea {
  margin-top: 0px;
}
.mdl-grid.grid-detail .mdl-txtbox,
.mdl-formarea .mdl-number {
  border: none;
  outline: none;
}
.mdl-formarea span {
  padding-bottom: 3px;
}
.mdl-formarea .mdl-number {
  text-align: right;
}
.mdl-grid.mdl-grid-result {
  grid-template-columns: 1fr 250px;
}
.mdl-grid.mdl-list-inner {
  grid-template-columns: minmax(150px, 1fr) 150px 100px 150px 100px;
}
.mdl-grid.mdl-list-detail {
  grid-template-columns: 24px 1fr;
}
.mdl-grid.mdl-list-detail span:first-child::after {
  content: ":";
}

.modal-dialog.modal-lg.mdl-lg {
  max-width: 70%;
}
.mdl-grid.mdl-w50 {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
}
.mdl-list-outer-head {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mdl-list-outer {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}
.mdl-list-outer.history-diff {
  height: 155px;
}
.mdl-list-outer-value {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 175px;
  overflow-y: auto;
}
.mdl-list-outer.border {
  margin-left: 12px;
  padding: 6px;
}
.mdl-list-inner {
  border-bottom: 1px solid #8f8f8f;
}
.mdl-list-inner span {
  display: block;
  margin: 6px 0;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mdl-list-result li:not(.list-head):hover {
  background-color: #2f7eeb;
  color: #ffffff;
}
.mdl-border {
  border-bottom: 1px solid #8f8f8f !important;
}
.mdl-border-active {
  border-bottom: 3px solid #2f7eeb !important;
}
.mdl-edit li:not(.list-head) > span:not(.mdl-btn-del) {
  border-bottom: 1px solid #8f8f8f;
  margin-right: 12px;
}
.mdl-edit li.list-head > span:not(:first-child),
.mdl-edit li:not(.list-head) span.mdl-btn-del {
  text-align: center;
}
.mdl-grid.mdl-list-edit {
  grid-template-columns: 54px 1fr 30px;
}
.mdl-grid.mdl-list-edit span {
  height: auto;
  margin-bottom: 6px;
  padding: 6px 0;
  color: var(--detail-txt-color);
}
.mdl-btn-del.btn-danger {
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  height: 25px;
  line-height: 25px;
  margin-top: 3px;
  text-align: center;
  width: 25px;
}
.modal#mdl-code_mng-edit {
  background-color: rgba(33, 37, 41, 0.5);
}
.btn-position-right {
  right: 21px;
  position: absolute;
}
.btn-position-left {
  left: 21px;
  position: absolute;
}
.modal-footer .btn-position-left {
  bottom: 16px;
}
.code-definition-modal {
  border: none;
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  width: auto;
  position: relative;
}
.code-definition-overray {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.code-link {
  margin-right: 5px;
  cursor: pointer;
  background: var(--ic-link) no-repeat;
  width: 20px;
  height: 15px;
  display: inline-block;
}
.code-info {
  width: 800px;
}
.tip-content {
  color: #3386e4;
}
.react-tooltip-lite {
  border-radius: 4px;
}
.topic-comment-border {
  border-top: 1px solid var(--detail-line-color);
}

.topic-delete {
  margin: 0 0 0 auto;
}

.topic-comment {
  white-space: pre-line;
  line-height: 1.2em;
  font-size: 12px;
  text-align: left;
  color: var(--detail-txt-color);
}

.topic-comment.deleted {
  color: var(--detail-key-name-txt-color);
}

.topic-comment-editor {
  position: fixed;
  bottom: 10px;
  padding-left: 24px;
  width: 100%;
  border-top: 2px solid var(--detail-line-color);
}

.topic-comment-editor > div {
  display: inline-block;
  width: 50%;
}

.topic-comment-editor > div > span {
  color: var(--detail-txt-color);
}

.topic-comment-editor > button {
  display: inline-block;
}

.topic-status,
.topic-category {
  display: inline-block;
  vertical-align: middle;
  color: #1e1e1e;
  font-weight: 500;
}

.topic-status.list {
  width: 50px;
  font-size: 10px;
}

.topic-status.detail {
  width: 55px;
  font-size: 11px;
}

.topic-status.open {
  color: #87ceeb;
  border: 1px solid #87ceeb;
}

.topic-status.resolve {
  color: #a9a9a9;
  border: 1px solid #a9a9a9;
}

.topic-category.list {
  width: 45px;
  font-size: 10px;
}

.topic-category.detail {
  width: 50px;
  font-size: 13px;
}

.topic-category.request {
  background-color: #90ee90;
}

.topic-category.bug {
  background-color: #e87758;
}

.topic-category.question {
  background-color: #e07b9a;
}

.topic-category.other {
  background-color: #4682b4;
}

.topic-filter {
  display: grid;
  grid-template-columns: 75px 75px 75px 75px;
}

.trash-box {
  background: url("../images/ic_trash_box.svg") no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.trash-box:hover {
  background: var(--trash-box-active-image) no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.switch-resolve-color {
  color: #00cc00;
}

.switch-open-color {
  color: var(--detail-txt-color);
}

/* code */
.code-value-add {
  padding-top: 15px;
}
.code-edit {
  height: 34px;
}
.btn-add-right {
  background-color: #2f7eeb;
  border-radius: 50%;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  height: 20px !important;
  line-height: 18px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 20px !important;
  min-width: initial !important;
}
.gridlist {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  margin-bottom: 0;
}
.gridlist-ttl,
.gridlist-status {
  height: 30px;
  padding: 5px;
}
.gridlist-ttl {
  font-weight: bold;
}
.gridlist-status {
  border-bottom: 3px solid #2f7eeb !important;
}

/* ***** CSS更新 ************************************************** */
.mdl-btn-del.btn-danger {
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-top: 3px;
  text-align: center;
  width: 20px;
}

/* ***** CSS追加 ************************************************** */
.definition-add {
  cursor: pointer;
  display: block;
  padding: 3px 5px;
  width: 296px;
  display: flex;
}
.definition-add.active {
  background-color: var(--lv-bg-03);
}
.definition-add.actve,
.definition-add:hover {
  /*    background-color: #2f4858; */
  background-color: var(--lv-bg-03);
  color: #fff;
}
.definition-add-icon {
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}
.definition-add-txt {
  color: var(--lv-txt-color-03);
  line-height: 2;
}
.definition-list .list-group-item {
  background: transparent;
  border: none;
  font-size: 12px;
  line-height: 33px;
  padding: 0 0.5rem 0 1rem;
}
.definition-list .list-group-item:hover,
.definition-list .list-group-item.active {
  background-color: #2f4858;
  color: #e0e0e0;
  cursor: pointer;
}
.definition-list-date {
  color: #fff;
  font-size: 10px;
}

.definition-list-dsp,
.definition-list-edt {
  overflow-y: auto;
  min-height: 35px;
  max-height: 15em;
  /*    max-height: calc(100vh - 600px); */
  margin-bottom: 6px;
  width: 350px;
}
.definition-list-modal {
  overflow-y: auto;
  height: 100%;
  max-height: 100px;
  margin-bottom: 6px;
  width: 350px;
}

.definition-list-add {
  overflow-y: auto;
  height: 11em;
  margin-bottom: 6px;
  width: 350px;
}
.definition-grid-dsp,
.definition-grid-edt,
.definition-grid-modal,
.definition-grid-add {
  grid-template-columns: 54px 250px 30px;
}
.definition-list-dsp .definition-grid-dsp > span,
.definition-list-edt .definition-grid-edt > span,
.definition-list-modal .definition-grid-modal > span,
.definition-list-add .definition-grid-add > span {
  margin: 0 6px 5px 0;
  padding: 5px 0;
  color: var(--detail-txt-color);
}
.definition-list-dsp .definition-grid-dsp > span,
.definition-list-edt .definition-grid-edt > span,
.definition-list-modal .definition-grid-modal > span {
  border-bottom: 1px solid var(--detail-line-color);
}
.definition-list-add .definition-grid-add > span {
  border-bottom: none;
}
.definition-grid-add:hover {
  cursor: move;
}
.definition-list-dsp + .btn.btn-primary,
.definition-list-edt + .btn.btn-primary,
.definition-list-add + .btn.btn-primary {
  width: 300px;
}
.definition-list-edt .mdl-btn-del.btn-danger {
  line-height: 22px;
}
.definition-btn-left {
  justify-content: initial;
}
#detail.tab-content {
  margin-left: 0;
}
.detail-sub-emphasis {
  color: #8f8f8f;
}
.connection-list {
  overflow-y: auto;
  height: 100%;
  max-height: 275px;
}
.connection-list.detail {
  overflow-y: auto;
  height: 100%;
  max-height: 125px;
}
.connect-grid-dsp {
  border-bottom: 1px solid var(--detail-line-color);
  cursor: default;
  grid-template-columns:
    minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
    minmax(250px, 1fr) minmax(250px, 1fr);
  min-width: 800px;
}
.connect-grid-dsp.list-head {
  background-color: var(--detail-bg-color);
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}
.connect-grid-dsp:not(.list-head) {
  cursor: pointer;
}
.connect-grid-dsp:not(.list-head):hover {
  background-color: #2f4858;
  color: #fff;
}
.connect-grid-dsp {
  overflow: auto;
  padding: 4px 6px 4px 0;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
  color: #8f8f8f;
}
.connect-grid-dsp.list-head span {
  overflow: auto;
  padding: 6px 6px 6px 0;
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
  color: var(--detail-txt-color);
}

.mdl-grid.definition-grid-basic {
  grid-template-columns: 60px 170px 120px;
}
#definition-add {
  margin-top: 18px;
}
#definition-add .definition-list-add {
  height: calc(100vh - 475px);
}
.style-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.style-scrollbar::-webkit-scrollbarkit-scrollbar-track {
  border-radius: 10px;
}
.style-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.code-basic-info-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 33px;
}

.code-basic-info-value {
  color: var(--detail-txt-color);
  line-height: 33px;
}

.code-key {
  width: 48px;
  word-wrap: break-word;
}

.code-definition-modal-bg {
  background-color: var(--detail-bg-color);
}

.code-definition-modal-input {
  background-color: var(--detail-bg-color);
  color: var(--detail-txt-color);
}

.code-definition-modal-text {
  color: var(--detail-txt-color);
}

.code-tree-filter {
  width: 50%;
}

.code-tree-filter > button {
  font-size: 10px;
  width: 144px;
  position: relative;
  display: inline-flex;
}

.code-tree-filter > button.category {
  margin: 0 4px;
}

.code-tree-filter > button.code-ame {
  margin-right: 4px;
}

.code-tree-filter > button[aria-expanded="false"]:after {
  content: var(--ic-arrow-down);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.code-tree-filter > button[aria-expanded="false"]:hover:after {
  content: var(--ic-arrow-down-hover);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.code-tree-filter > button[aria-expanded="true"]:after {
  content: var(--ic-arrow-up);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.ic-close {
  background: var(--ic-close) no-repeat;
}

.code-tree-filter > button[aria-expanded="true"]:hover:after {
  content: var(--ic-arrow-up-hover);
  display: block;
  position: absolute;
  top: 12%;
  right: 0px;
}

.mdl-list-outer-modal {
  overflow-y: auto;
  height: 10em;
}

.mdl-list-outer-instance {
  overflow-y: auto;
  width: 100%;
  padding-right: 200px;
  min-width: 600px;
}

.mdl-list-outer-group-user {
  overflow-y: auto;
  max-height: 500px;
  width: calc(100% - 202px);
  margin-left: 33px;
}

.mdl-list-outer-policy-res {
  overflow-y: hidden;
  max-height: 1000px;
}
.mdl-list-outer-policy-res:hover {
  overflow-y: auto;
}

.group-sub-title {
  width: 100%;
  padding-right: 200px;
}

.mdl-list-outer-modal thead th {
  position: sticky;
  top: 0;
}

.table-code-link {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-code-link td,
.table-code-link th {
  border-bottom: 1px solid var(--detail-line-color) !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  font-size: 12px;
}

.table-code-link td {
  color: var(--detail-key-name-txt-color);
}

.table-code-link th {
  color: var(--detail-txt-color);
  background-color: var(--detail-bg-color);
}

.modal-code-untied {
  width: 100%;
  text-align: center;
}

.db-drop-target {
  border: 2px dotted var(--db-drop-line-color);
  margin-right: 10px;
}

.db-drop-msg {
  position: absolute;
  margin-top: 200px;
}

.diff-bottom {
  height: 76px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 1px solid var(--detail-line-color);
}

.diff-bottom-left {
  display: grid;
  grid-template-columns: calc(100% - 170px) 160px;
}

.diff-bottom-right {
  display: grid;
  grid-template-columns: 50px calc(100% - 220px) 160px;
}

.table-path {
  word-wrap: break-word;
}

.select-date-right {
  position: absolute;
  right: 30px;
}

.move {
  cursor: move;
}

.position-container {
  width: 100%;
  background-color: var(--lv-bg-color);
  color: var(--lv-txt-color-02);
  border-radius: 0;
}

.position-container.select {
  width: 100%;
  background-color: var(--lv-bg-03);
  color: var(--lv-txt-color-03);
}

.ic-drag {
  background: var(--ic-drag) left no-repeat;
  background-position: 5px 5px !important;
}

.position-drag {
  margin-left: 30px !important;
}

.position-no-drag {
  margin-left: 10px !important;
}

.lv-group-user {
  max-height: 150px;
  min-height: 30px;
  overflow-y: scroll;
}

.mr-8px {
  margin-right: 8px;
}

.modal-bottom-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 80vh;
  overflow-y: auto;
}

.mdl-list-modal-outer {
  height: 50vh;
  overflow: auto;
}

.mdl-list-modal-user {
  grid-template-columns: 60px 200px 1fr 150px;
  border-bottom: 1px solid #8f8f8f;
}

.mdl-list-modal-group,
.mdl-list-modal-policy,
.mdl-list-modal-user-group {
  grid-template-columns: 60px 250px 1fr;
  border-bottom: 1px solid #8f8f8f;
}

.mdl-list-modal-user span,
.mdl-list-modal-group span,
.mdl-list-modal-policy span,
.mdl-list-modal-user-group span {
  display: block;
  margin: 6px 0;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mdl-list-user {
  grid-template-columns: 30% 70%;
  border-bottom: 1px solid var(--detail-line-color);
}

.mdl-list-user-edit {
  grid-template-columns: 80px 20px 80px;
}

.mdl-list-user-header {
  width: calc(100% - 170px);
}

.policy-menu {
  line-height: unset !important;
}

.mdl-list-policy-meta {
  grid-template-columns: 320px 35px 35px;
}

.mdl-list-policy-dbcode {
  grid-template-columns: 320px 35px 35px 35px 35px;
}

.mdl-list-policy-res {
  grid-template-columns: 70px 150px 1fr;
}

.policy-description {
  background-color: transparent !important;
  width: 100%;
  padding: 0px;
  min-height: 100%;
  border: none;
  line-height: 1.2em;
}

#jsoneditor {
  width: 100%;
  height: 300px;
}

.no-hover{
  pointer-events: none;
}

.break-text{
  word-break: break-all;
}

.policy-description {
  white-space: pre-line;
  word-break: break-all;
  font-size: 12px;
  text-align: left;
}

.btn-modal{
  color: #ffffff;
}